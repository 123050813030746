export default {
  setOffices: (state, data) => {
    state.offices = data;
  },

  setNewOffice: (state, data) => {
    state.offices = {
      ...state.offices,
      results: [...state.offices.results, data]
    };
  }
};

/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Access {
    
    constructor() { 
        
        Access.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Access();

            if (data.hasOwnProperty('mytech')) {
                obj['mytech'] = ApiClient.convertToType(data['mytech'], 'Boolean');
            }
            if (data.hasOwnProperty('qrcode')) {
                obj['qrcode'] = ApiClient.convertToType(data['qrcode'], 'Boolean');
            }
            if (data.hasOwnProperty('myaccount')) {
                obj['myaccount'] = ApiClient.convertToType(data['myaccount'], 'Boolean');
            }
            if (data.hasOwnProperty('mycalendar')) {
                obj['mycalendar'] = ApiClient.convertToType(data['mycalendar'], 'Boolean');
            }
            if (data.hasOwnProperty('myrequests')) {
                obj['myrequests'] = ApiClient.convertToType(data['myrequests'], 'Boolean');
            }
        }
        return obj;
    }


}


Access.prototype['mytech'] = undefined;


Access.prototype['qrcode'] = undefined;


Access.prototype['myaccount'] = undefined;


Access.prototype['mycalendar'] = undefined;


Access.prototype['myrequests'] = undefined;






export default Access;


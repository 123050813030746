/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Parameter from './Parameter';
import TechniqueCreateAllOf from './TechniqueCreateAllOf';
import TechniqueEdit from './TechniqueEdit';


class TechniqueCreate {
    
    constructor(sku, title, description, parameters, categoryId) { 
        TechniqueCreateAllOf.initialize(this, sku);TechniqueEdit.initialize(this, title, description, parameters, categoryId);
        TechniqueCreate.initialize(this, sku, title, description, parameters, categoryId);
    }

    
    static initialize(obj, sku, title, description, parameters, categoryId) { 
        obj['sku'] = sku;
        obj['title'] = title;
        obj['description'] = description;
        obj['parameters'] = parameters;
        obj['categoryId'] = categoryId;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueCreate();
            TechniqueCreateAllOf.constructFromObject(data, obj);
            TechniqueEdit.constructFromObject(data, obj);

            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [Parameter]);
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], ['String']);
            }
        }
        return obj;
    }


}


TechniqueCreate.prototype['sku'] = undefined;


TechniqueCreate.prototype['title'] = undefined;


TechniqueCreate.prototype['description'] = undefined;


TechniqueCreate.prototype['parameters'] = undefined;


TechniqueCreate.prototype['categoryId'] = undefined;


TechniqueCreate.prototype['files'] = undefined;


// Implement TechniqueCreateAllOf interface:

TechniqueCreateAllOf.prototype['sku'] = undefined;
// Implement TechniqueEdit interface:

TechniqueEdit.prototype['title'] = undefined;

TechniqueEdit.prototype['description'] = undefined;

TechniqueEdit.prototype['parameters'] = undefined;

TechniqueEdit.prototype['categoryId'] = undefined;

TechniqueEdit.prototype['files'] = undefined;




export default TechniqueCreate;


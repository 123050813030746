export default {
  isAuthenticated(state) {
    return state.isAuth
      ? state.isAuth
      : !!window.localStorage.getItem("token") &&
          !!window.localStorage.getItem("refresh");
  },
  getRole(state) {
    return state.role;
  },
  getAuth(state) {
    return state;
  },
  isRefreshed(state) {
    return state.refreshed;
  },
  isShowInformation(state) {
    return state.isShowInfoLabels;
  }
};

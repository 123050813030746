export function isTokenValid() {
  let result;

  if (!!localStorage.getItem("token") && !!localStorage.getItem("refresh")) {
    let accessToken = localStorage.getItem("token");
    let expiredDate = JSON.parse(atob(accessToken.split(".")[1])).exp;
    if (expiredDate) {
      let currentDate = Math.ceil(new Date().getTime() / 1000);
      result = currentDate <= expiredDate;
    } else {
      result = false;
    }
  } else {
    result = false;
  }

  return result;
}

export function isTokenValidV2() {
  let result;

  if (
    !!localStorage.getItem("accessTokenV2") &&
    !!localStorage.getItem("refreshTokenV2")
  ) {
    let accessToken = localStorage.getItem("accessTokenV2");
    let expiredDate = JSON.parse(atob(accessToken.split(".")[1])).exp;
    if (expiredDate) {
      let currentDate = Math.ceil(new Date().getTime() / 1000);
      result = currentDate <= expiredDate;
    } else {
      result = false;
    }
  } else {
    result = false;
  }

  return result;
}

/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class RecoveryPasswordRequest {
    
    constructor(email) { 
        
        RecoveryPasswordRequest.initialize(this, email);
    }

    
    static initialize(obj, email) { 
        obj['email'] = email;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RecoveryPasswordRequest();

            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
        }
        return obj;
    }


}


RecoveryPasswordRequest.prototype['email'] = undefined;






export default RecoveryPasswordRequest;


/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Access from './Access';


class User {
    
    constructor(id, email, role, active) { 
        
        User.initialize(this, id, email, role, active);
    }

    
    static initialize(obj, id, email, role, active) { 
        obj['id'] = id;
        obj['email'] = email;
        obj['role'] = role;
        obj['active'] = active;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Number');
            }
            if (data.hasOwnProperty('companyTitle')) {
                obj['companyTitle'] = ApiClient.convertToType(data['companyTitle'], 'String');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'Number');
            }
            if (data.hasOwnProperty('officeTitle')) {
                obj['officeTitle'] = ApiClient.convertToType(data['officeTitle'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('profilePicture')) {
                obj['profilePicture'] = ApiClient.convertToType(data['profilePicture'], 'String');
            }
            if (data.hasOwnProperty('dateJoined')) {
                obj['dateJoined'] = ApiClient.convertToType(data['dateJoined'], 'Date');
            }
            if (data.hasOwnProperty('lastLogin')) {
                obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'Date');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'Number');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('superuser')) {
                obj['superuser'] = ApiClient.convertToType(data['superuser'], 'Boolean');
            }
            if (data.hasOwnProperty('staff')) {
                obj['staff'] = ApiClient.convertToType(data['staff'], 'Boolean');
            }
            if (data.hasOwnProperty('access')) {
                obj['access'] = Access.constructFromObject(data['access']);
            }
        }
        return obj;
    }


}


User.prototype['id'] = undefined;


User.prototype['companyId'] = undefined;


User.prototype['companyTitle'] = undefined;


User.prototype['officeId'] = undefined;


User.prototype['officeTitle'] = undefined;


User.prototype['email'] = undefined;


User.prototype['phone'] = undefined;


User.prototype['firstName'] = undefined;


User.prototype['lastName'] = undefined;


User.prototype['profilePicture'] = undefined;


User.prototype['dateJoined'] = undefined;


User.prototype['lastLogin'] = undefined;


User.prototype['role'] = undefined;


User.prototype['active'] = undefined;


User.prototype['superuser'] = undefined;


User.prototype['staff'] = undefined;


User.prototype['access'] = undefined;






export default User;


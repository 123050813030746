export default {
  setErrors(state, data) {
    state.errors = data;
  },
  clearSingleError(state, field) {
    const errors = state.errors;
    for (let key in errors) {
      if (key === field) {
        delete errors[field];
      }
    }
    state.errors = errors;
  },
  clearErrors(state) {
    state.errors = {};
  }
};

/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class InlineObject {
    
    constructor(active) { 
        
        InlineObject.initialize(this, active);
    }

    
    static initialize(obj, active) { 
        obj['active'] = active || true;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject();

            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
        }
        return obj;
    }


}


InlineObject.prototype['active'] = true;






export default InlineObject;


/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class User {
    
    constructor(id, firstName, lastName) { 
        
        User.initialize(this, id, firstName, lastName);
    }

    
    static initialize(obj, id, firstName, lastName) { 
        obj['id'] = id;
        obj['firstName'] = firstName;
        obj['lastName'] = lastName;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('photo')) {
                obj['photo'] = ApiClient.convertToType(data['photo'], 'String');
            }
        }
        return obj;
    }


}


User.prototype['id'] = undefined;


User.prototype['firstName'] = undefined;


User.prototype['lastName'] = undefined;


User.prototype['photo'] = undefined;






export default User;


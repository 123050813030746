/**
 * Company API
 * Used for: - Manage Company 
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CompanyEdit from './CompanyEdit';


class CompanyCreate {
    
    constructor(email) { 
        CompanyEdit.initialize(this, email);
        CompanyCreate.initialize(this, email);
    }

    
    static initialize(obj, email) { 
        obj['email'] = email;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyCreate();
            CompanyEdit.constructFromObject(data, obj);

            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('website')) {
                obj['website'] = ApiClient.convertToType(data['website'], 'String');
            }
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
            }
            if (data.hasOwnProperty('inn')) {
                obj['inn'] = ApiClient.convertToType(data['inn'], 'String');
            }
            if (data.hasOwnProperty('kpp')) {
                obj['kpp'] = ApiClient.convertToType(data['kpp'], 'String');
            }
            if (data.hasOwnProperty('ogrn')) {
                obj['ogrn'] = ApiClient.convertToType(data['ogrn'], 'String');
            }
            if (data.hasOwnProperty('legalEntity')) {
                obj['legalEntity'] = ApiClient.convertToType(data['legalEntity'], 'String');
            }
            if (data.hasOwnProperty('legalEntityAddress')) {
                obj['legalEntityAddress'] = ApiClient.convertToType(data['legalEntityAddress'], 'String');
            }
            if (data.hasOwnProperty('factAddress')) {
                obj['factAddress'] = ApiClient.convertToType(data['factAddress'], 'String');
            }
            if (data.hasOwnProperty('bankAccountNum')) {
                obj['bankAccountNum'] = ApiClient.convertToType(data['bankAccountNum'], 'String');
            }
            if (data.hasOwnProperty('bankName')) {
                obj['bankName'] = ApiClient.convertToType(data['bankName'], 'String');
            }
            if (data.hasOwnProperty('bankInn')) {
                obj['bankInn'] = ApiClient.convertToType(data['bankInn'], 'String');
            }
            if (data.hasOwnProperty('bankKs')) {
                obj['bankKs'] = ApiClient.convertToType(data['bankKs'], 'String');
            }
            if (data.hasOwnProperty('bankBik')) {
                obj['bankBik'] = ApiClient.convertToType(data['bankBik'], 'String');
            }
            if (data.hasOwnProperty('crmManagerId')) {
                obj['crmManagerId'] = ApiClient.convertToType(data['crmManagerId'], 'Number');
            }
            if (data.hasOwnProperty('profilePicture')) {
                obj['profilePicture'] = ApiClient.convertToType(data['profilePicture'], 'String');
            }
            if (data.hasOwnProperty('statusRegistration')) {
                obj['statusRegistration'] = ApiClient.convertToType(data['statusRegistration'], 'String');
            }
        }
        return obj;
    }


}


CompanyCreate.prototype['phone'] = undefined;


CompanyCreate.prototype['email'] = undefined;


CompanyCreate.prototype['website'] = undefined;


CompanyCreate.prototype['brand'] = undefined;


CompanyCreate.prototype['inn'] = undefined;


CompanyCreate.prototype['kpp'] = undefined;


CompanyCreate.prototype['ogrn'] = undefined;


CompanyCreate.prototype['legalEntity'] = undefined;


CompanyCreate.prototype['legalEntityAddress'] = undefined;


CompanyCreate.prototype['factAddress'] = undefined;


CompanyCreate.prototype['bankAccountNum'] = undefined;


CompanyCreate.prototype['bankName'] = undefined;


CompanyCreate.prototype['bankInn'] = undefined;


CompanyCreate.prototype['bankKs'] = undefined;


CompanyCreate.prototype['bankBik'] = undefined;


CompanyCreate.prototype['crmManagerId'] = undefined;


CompanyCreate.prototype['profilePicture'] = undefined;


CompanyCreate.prototype['statusRegistration'] = undefined;


// Implement CompanyEdit interface:

CompanyEdit.prototype['phone'] = undefined;

CompanyEdit.prototype['email'] = undefined;

CompanyEdit.prototype['website'] = undefined;

CompanyEdit.prototype['brand'] = undefined;

CompanyEdit.prototype['inn'] = undefined;

CompanyEdit.prototype['kpp'] = undefined;

CompanyEdit.prototype['ogrn'] = undefined;

CompanyEdit.prototype['legalEntity'] = undefined;

CompanyEdit.prototype['legalEntityAddress'] = undefined;

CompanyEdit.prototype['factAddress'] = undefined;

CompanyEdit.prototype['bankAccountNum'] = undefined;

CompanyEdit.prototype['bankName'] = undefined;

CompanyEdit.prototype['bankInn'] = undefined;

CompanyEdit.prototype['bankKs'] = undefined;

CompanyEdit.prototype['bankBik'] = undefined;

CompanyEdit.prototype['crmManagerId'] = undefined;

CompanyEdit.prototype['profilePicture'] = undefined;

CompanyEdit.prototype['statusRegistration'] = undefined;




export default CompanyCreate;


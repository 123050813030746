import Vue from "vue";
import Vuex from "vuex";
import offices from "./modules/offices";
import devices from "./modules/devices";
import tags from "./modules/tags";
import user from "./modules/user";
import auth from "./modules/auth";
import errors from "./modules/errors";
import mainCompanyInfo from "./modules/mainCompanyInfo";
import bankInfo from "./modules/bankData";
import notifications from "./modules/notifications";
import calendar from "./modules/calendar";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    offices,
    devices,
    tags,
    user,
    auth,
    errors,
    mainCompanyInfo,
    bankInfo,
    notifications,
    calendar
  }
});

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import moment from "moment";
import localization from "moment/locale/ru";

import "./utils/directives";

import { overwriteOrderHistory } from "@/helpers/redefinition";

overwriteOrderHistory();

moment.updateLocale("ru", localization);
// moment.locale("ru", localization);
Vue.prototype.moment = moment;

export { toggleTheme, theme };
let theme = localStorage.theme;
if (localStorage.theme === undefined) {
  localStorage.setItem("theme", "light-theme");
}
function toggleTheme() {
  if (localStorage.theme === "dark-theme") {
    import("./resource/styles/base2.scss");
  } else if (localStorage.theme === "light-theme") {
    import("./resource/styles/base.scss");
  }
}
toggleTheme();
import { BootstrapVue } from "bootstrap-vue";
import "./function/request/RequestManagerInit";
import VueCookie from "vue-cookie";
import axios from "axios";

import { VERSION_CHECK_INTERVAL_SEC } from "./app-config";
import AutoReload from "./plugins/AutoReload";

import vuescroll from "vuescroll";
Vue.config.productionTip = false;

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import {
  highlight,
  dateTimeFromDateString,
  dateTimeWithoutLocale,
  money
} from "@/utils/filters";
Vue.filter("highlight", highlight);
Vue.filter("dateTimeFromDateString", dateTimeFromDateString);
Vue.filter("dateTimeWithoutLocale", dateTimeWithoutLocale);
Vue.filter("money", money);

Vue.use(VueCookie);
Vue.use(BootstrapVue);
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      sizeStrategy: "number"
    },
    scrollPanel: {
      speed: 300,
      // maxHeight: 810,
      scrollingX: true
    },
    rail: {
      background: "#ffffff",
      opacity: 0,
      size: "10px",
      specifyBorderRadius: false,
      gutterOfEnds: null,
      gutterOfSide: "2px",
      keepShow: false
    },
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: false,
      keepShow: false,
      background: "#006b90",
      opacity: 0.5,
      specifyBorderRadius: "1px",
      minSize: 0.1,
      size: "10px"
    }
  }
});
new Vue({
  router,
  store,

  created() {
    Vue.use(AutoReload, {
      config: {
        Enabled: true,
        CheckInterval: VERSION_CHECK_INTERVAL_SEC,
        Notification: true
      },
      router: this.$router,
      vm: this
    });
  },

  render: h => h(App)
}).$mount("#app");

Vue.prototype.axios = axios;

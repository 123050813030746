/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NeoPharmOrder from '../model/NeoPharmOrder';
import NeoPharmRequest from '../model/NeoPharmRequest';
import Order from '../model/Order';


export default class OrderApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createNeoPharmOrderWithHttpInfo(debug, body) {
      let postBody = body;
      // verify the required parameter 'debug' is set
      if (debug === undefined || debug === null) {
        throw new Error("Missing the required parameter 'debug' when calling createNeoPharmOrder");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createNeoPharmOrder");
      }

      let pathParams = {
      };
      let queryParams = {
        'debug': debug
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NeoPharmRequest;
      return this.apiClient.callApi(
        '/neo-pharm/orders/system', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createNeoPharmOrder(debug, body) {
      return this.createNeoPharmOrderWithHttpInfo(debug, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrderWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createOrder");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/neo-pharm/orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrder(body) {
      return this.createOrderWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DeviceShort from './DeviceShort';


class Report {
    
    constructor(device, total, perDay) { 
        
        Report.initialize(this, device, total, perDay);
    }

    
    static initialize(obj, device, total, perDay) { 
        obj['device'] = device;
        obj['total'] = total;
        obj['perDay'] = perDay;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Report();

            if (data.hasOwnProperty('device')) {
                obj['device'] = DeviceShort.constructFromObject(data['device']);
            }
            if (data.hasOwnProperty('counter')) {
                obj['counter'] = ApiClient.convertToType(data['counter'], 'String');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
            if (data.hasOwnProperty('perDay')) {
                obj['perDay'] = ApiClient.convertToType(data['perDay'], 'Number');
            }
        }
        return obj;
    }


}


Report.prototype['device'] = undefined;


Report.prototype['counter'] = undefined;


Report.prototype['total'] = undefined;


Report.prototype['perDay'] = undefined;






export default Report;


/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class OrderCreateQrCode {
    
    constructor(title, texts, typeId, statusId, priority) { 
        
        OrderCreateQrCode.initialize(this, title, texts, typeId, statusId, priority);
    }

    
    static initialize(obj, title, texts, typeId, statusId, priority) { 
        obj['title'] = title;
        obj['texts'] = texts;
        obj['typeId'] = typeId;
        obj['statusId'] = statusId;
        obj['priority'] = priority;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderCreateQrCode();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('texts')) {
                obj['texts'] = ApiClient.convertToType(data['texts'], {'String': 'String'});
            }
            if (data.hasOwnProperty('typeId')) {
                obj['typeId'] = ApiClient.convertToType(data['typeId'], 'String');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('filePaths')) {
                obj['filePaths'] = ApiClient.convertToType(data['filePaths'], ['String']);
            }
        }
        return obj;
    }


}


OrderCreateQrCode.prototype['title'] = undefined;


OrderCreateQrCode.prototype['texts'] = undefined;


OrderCreateQrCode.prototype['typeId'] = undefined;


OrderCreateQrCode.prototype['statusId'] = undefined;


OrderCreateQrCode.prototype['priority'] = undefined;


OrderCreateQrCode.prototype['filePaths'] = undefined;






export default OrderCreateQrCode;


/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Company from './Company';


class Office {
    
    constructor() { 
        
        Office.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Office();

            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'String');
            }
            if (data.hasOwnProperty('neoPharmOfficeId')) {
                obj['neoPharmOfficeId'] = ApiClient.convertToType(data['neoPharmOfficeId'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = Company.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
        }
        return obj;
    }


}


Office.prototype['officeId'] = undefined;


Office.prototype['neoPharmOfficeId'] = undefined;


Office.prototype['company'] = undefined;


Office.prototype['name'] = undefined;


Office.prototype['city'] = undefined;






export default Office;


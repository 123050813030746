/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ChangePermissionsHistoryAllOf from './ChangePermissionsHistoryAllOf';
import History from './History';
import User from './User';


class ChangePermissionsHistory {
    
    constructor(type, timestamp, user, action) { 
        History.initialize(this, type, timestamp);ChangePermissionsHistoryAllOf.initialize(this, user, action);
        ChangePermissionsHistory.initialize(this, type, timestamp, user, action);
    }

    
    static initialize(obj, type, timestamp, user, action) { 
        obj['user'] = user;
        obj['action'] = action;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangePermissionsHistory();
            History.constructFromObject(data, obj);
            History.constructFromObject(data, obj);
            ChangePermissionsHistoryAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
        }
        return obj;
    }


}


ChangePermissionsHistory.prototype['user'] = undefined;


ChangePermissionsHistory.prototype['action'] = undefined;


ChangePermissionsHistory.prototype['role'] = undefined;


// Implement History interface:

History.prototype['type'] = undefined;

History.prototype['timestamp'] = undefined;
// Implement ChangePermissionsHistoryAllOf interface:

ChangePermissionsHistoryAllOf.prototype['user'] = undefined;

ChangePermissionsHistoryAllOf.prototype['action'] = undefined;

ChangePermissionsHistoryAllOf.prototype['role'] = undefined;




ChangePermissionsHistory['ActionEnum'] = {

    
    "add": "add",

    
    "remove": "remove",

    
    "newRole": "newRole",

    
    "removeRole": "removeRole"
};



export default ChangePermissionsHistory;


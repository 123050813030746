/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CategoryAllOf from './CategoryAllOf';
import CategoryEdit from './CategoryEdit';
import CategoryParameter from './CategoryParameter';


class Category {
    
    constructor(id, title, description, parameters) { 
        CategoryAllOf.initialize(this, id);CategoryEdit.initialize(this, title, description, parameters);
        Category.initialize(this, id, title, description, parameters);
    }

    
    static initialize(obj, id, title, description, parameters) { 
        obj['id'] = id;
        obj['title'] = title;
        obj['description'] = description;
        obj['parameters'] = parameters;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Category();
            CategoryAllOf.constructFromObject(data, obj);
            CategoryEdit.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('parent')) {
                obj['parent'] = ApiClient.convertToType(data['parent'], 'Number');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [CategoryParameter]);
            }
        }
        return obj;
    }


}


Category.prototype['id'] = undefined;


Category.prototype['title'] = undefined;


Category.prototype['description'] = undefined;


Category.prototype['parent'] = undefined;


Category.prototype['parameters'] = undefined;


// Implement CategoryAllOf interface:

CategoryAllOf.prototype['id'] = undefined;
// Implement CategoryEdit interface:

CategoryEdit.prototype['title'] = undefined;

CategoryEdit.prototype['description'] = undefined;

CategoryEdit.prototype['parent'] = undefined;

CategoryEdit.prototype['parameters'] = undefined;




export default Category;


/**
 * Company API
 * Used for: - Manage Company 
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class DaData {
    
    constructor() { 
        
        DaData.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DaData();

            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('website')) {
                obj['website'] = ApiClient.convertToType(data['website'], 'String');
            }
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
            }
            if (data.hasOwnProperty('inn')) {
                obj['inn'] = ApiClient.convertToType(data['inn'], 'String');
            }
            if (data.hasOwnProperty('kpp')) {
                obj['kpp'] = ApiClient.convertToType(data['kpp'], 'String');
            }
            if (data.hasOwnProperty('ogrn')) {
                obj['ogrn'] = ApiClient.convertToType(data['ogrn'], 'String');
            }
            if (data.hasOwnProperty('legalEntity')) {
                obj['legalEntity'] = ApiClient.convertToType(data['legalEntity'], 'String');
            }
            if (data.hasOwnProperty('legalEntityAddress')) {
                obj['legalEntityAddress'] = ApiClient.convertToType(data['legalEntityAddress'], 'String');
            }
            if (data.hasOwnProperty('bankAccountNum')) {
                obj['bankAccountNum'] = ApiClient.convertToType(data['bankAccountNum'], 'String');
            }
            if (data.hasOwnProperty('bankName')) {
                obj['bankName'] = ApiClient.convertToType(data['bankName'], 'String');
            }
            if (data.hasOwnProperty('bankInn')) {
                obj['bankInn'] = ApiClient.convertToType(data['bankInn'], 'String');
            }
            if (data.hasOwnProperty('bankKs')) {
                obj['bankKs'] = ApiClient.convertToType(data['bankKs'], 'String');
            }
            if (data.hasOwnProperty('bankBik')) {
                obj['bankBik'] = ApiClient.convertToType(data['bankBik'], 'String');
            }
        }
        return obj;
    }


}


DaData.prototype['phone'] = undefined;


DaData.prototype['email'] = undefined;


DaData.prototype['website'] = undefined;


DaData.prototype['brand'] = undefined;


DaData.prototype['inn'] = undefined;


DaData.prototype['kpp'] = undefined;


DaData.prototype['ogrn'] = undefined;


DaData.prototype['legalEntity'] = undefined;


DaData.prototype['legalEntityAddress'] = undefined;


DaData.prototype['bankAccountNum'] = undefined;


DaData.prototype['bankName'] = undefined;


DaData.prototype['bankInn'] = undefined;


DaData.prototype['bankKs'] = undefined;


DaData.prototype['bankBik'] = undefined;






export default DaData;


export const translateErrors = errors => {
  for (let key in errors) {
    if (key === "email") {
      errors[key] === "This field may not be blank." &&
        (errors[key] = "Введите email");
      errors[key] === "Enter a valid email address." &&
        (errors[key] = "Введите корректный email адрес");
      errors[key] === "user with this Email already exists." &&
        (errors[key] = "Пользователь с таким email уже существует");
    }
    if (key === "role") {
      errors[key] === '"" is not a valid choice.' &&
        (errors[key] = "Выберите роль");
    }
  }
  return errors;
};

import RequestManager from "../../../function/request/RequestManager";

function getDateString(date) {
  const year = new Date(date).getFullYear();
  let month = new Date(date).getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = new Date(date).getDate();
  day = day < 10 ? "0" + day : day;
  return `${year}-${month}-${day}`;
}

function getTime(date) {
  const hours = new Date(date).getHours();
  let minutes = new Date(date).getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return hours + " : " + minutes;
}

export default {
  async getEvents({ commit }) {
    let ev = [];
    let evOrig = [];
    try {
      ev = await RequestManager().getCalendar();
      evOrig = ev;
      ev = ev.map(e => ({
        title: `${getTime(e.timestamp)}, ${e.description}`,
        date: getDateString(e.timestamp),
        rel_obj_type: e.rel_obj_type,
        rel_obj_id: e.rel_obj_id
      }));
    } finally {
      commit("setEvents", { ev, evOrig });
    }
  }
};

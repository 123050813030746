import RequestManager from "../../../function/request/RequestManager";
import { DeviceApi } from "../../../function/request/apiV2";

export default {
  GET_DEVICES_TYPES: ctx => {
    RequestManager()
      .getDevicesTypes()
      .then(data => {
        ctx.commit("setDevicesTypes", data);
      });
  },
  GET_DEVICES_STATUS: ctx => {
    RequestManager()
      .getDevicesStatus()
      .then(data => {
        ctx.commit("setDevicesStatus", data);
      });
  },
  SET_NEW_DEVICE: (ctx, device) => {
    ctx.commit("setNewDevice", device);
  },

  async getDevice({ commit }, id) {
    let dev1 = {};
    try {
      let deviceApi = new DeviceApi();
      dev1 = await deviceApi.api.getDevice(id);
    } finally {
      commit("setDevice", dev1);
    }
  },
  async deleteTag({ commit, state }, id) {
    const tags = state.currentDevice.tags.filter(tag => tag.id !== id);
    await RequestManager().updateSingleDevice(state.currentDevice.id, {
      tag_ids: tags.map(el => el.id)
    });

    commit("deleteTag", tags);
  }
};

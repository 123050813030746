import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  userRoles: {},
  currentUser: {},
  currentUserV2: {},
  adminProfile: {}
};
export default {
  state,
  mutations,
  getters,
  actions
};

/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class ReferenceHistoryAllOf {
    
    constructor(action, referenceType, referenceId, referenceTitle) { 
        
        ReferenceHistoryAllOf.initialize(this, action, referenceType, referenceId, referenceTitle);
    }

    
    static initialize(obj, action, referenceType, referenceId, referenceTitle) { 
        obj['action'] = action;
        obj['referenceType'] = referenceType;
        obj['referenceId'] = referenceId;
        obj['referenceTitle'] = referenceTitle;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReferenceHistoryAllOf();

            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('referenceType')) {
                obj['referenceType'] = ApiClient.convertToType(data['referenceType'], 'String');
            }
            if (data.hasOwnProperty('referenceId')) {
                obj['referenceId'] = ApiClient.convertToType(data['referenceId'], 'String');
            }
            if (data.hasOwnProperty('referenceTitle')) {
                obj['referenceTitle'] = ApiClient.convertToType(data['referenceTitle'], 'String');
            }
        }
        return obj;
    }


}


ReferenceHistoryAllOf.prototype['action'] = undefined;


ReferenceHistoryAllOf.prototype['referenceType'] = undefined;


ReferenceHistoryAllOf.prototype['referenceId'] = undefined;


ReferenceHistoryAllOf.prototype['referenceTitle'] = undefined;






ReferenceHistoryAllOf['ActionEnum'] = {

    
    "add": "add",

    
    "remove": "remove"
};



ReferenceHistoryAllOf['ReferenceTypeEnum'] = {

    
    "order": "order",

    
    "reverseOrder": "reverseOrder",

    
    "parentOrder": "parentOrder",

    
    "childOrder": "childOrder",

    
    "device": "device"
};



export default ReferenceHistoryAllOf;


/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class History {
    
    constructor(type, timestamp) { 
        
        History.initialize(this, type, timestamp);
    }

    
    static initialize(obj, type, timestamp) { 
        obj['type'] = type;
        obj['timestamp'] = timestamp;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new History();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
        }
        return obj;
    }


}


History.prototype['type'] = undefined;


History.prototype['timestamp'] = undefined;






History['TypeEnum'] = {

    
    "ChangeStatusHistory": "ChangeStatusHistory",

    
    "ReferenceHistory": "ReferenceHistory"
};



export default History;


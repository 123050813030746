/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderHierarchy from './OrderHierarchy';


class OrdersList {
    
    constructor(items, count, countWithNewMessages) { 
        
        OrdersList.initialize(this, items, count, countWithNewMessages);
    }

    
    static initialize(obj, items, count, countWithNewMessages) { 
        obj['items'] = items;
        obj['count'] = count;
        obj['countWithNewMessages'] = countWithNewMessages;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrdersList();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [OrderHierarchy]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
            if (data.hasOwnProperty('countWithNewMessages')) {
                obj['countWithNewMessages'] = ApiClient.convertToType(data['countWithNewMessages'], 'Number');
            }
        }
        return obj;
    }


}


OrdersList.prototype['items'] = undefined;


OrdersList.prototype['count'] = undefined;


OrdersList.prototype['countWithNewMessages'] = undefined;






export default OrdersList;


/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Technique from './Technique';


class TechniqueReferences {
    
    constructor() { 
        
        TechniqueReferences.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueReferences();

            if (data.hasOwnProperty('parents')) {
                obj['parents'] = ApiClient.convertToType(data['parents'], [Technique]);
            }
            if (data.hasOwnProperty('childs')) {
                obj['childs'] = ApiClient.convertToType(data['childs'], [Technique]);
            }
            if (data.hasOwnProperty('ancestors')) {
                obj['ancestors'] = ApiClient.convertToType(data['ancestors'], [Technique]);
            }
            if (data.hasOwnProperty('descendants')) {
                obj['descendants'] = ApiClient.convertToType(data['descendants'], [Technique]);
            }
        }
        return obj;
    }


}


TechniqueReferences.prototype['parents'] = undefined;


TechniqueReferences.prototype['childs'] = undefined;


TechniqueReferences.prototype['ancestors'] = undefined;


TechniqueReferences.prototype['descendants'] = undefined;






export default TechniqueReferences;


import RequestManager from "./RequestManager";
import { isTokenValidV2 } from "../checkExpiredToken";
import eventBus from "@/utils/event-bus";
import { WEBSOCKET_URL } from "@/app-config";
import WebSocketService from "@/utils/web-socket";

const baseUrl = process.env.VUE_APP_API_URL;

let webSocketService;

// class Api {
//   constructor() {
//     this.initialize();
//   }
//
//   initialize = async () => {
//     if (!isTokenValidV2()) {
//       await refreshTokenV2();
//     }
//   };
// }

class TechniqueApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().techniqueApi;
  };

  async getTechniqueFetch(id) {
    const tokenV2 = localStorage.getItem("accessTokenV2");
    const response = await fetch(`${baseUrl}/v2/technique/${id}`, {
      headers: {
        authorization: `Bearer ${tokenV2}`
      }
    });
    return await response.json();
  }
}

class CategoryApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().categoryApi;
  };
}

class CompanyApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().companyApi;
  };
}

class FilesApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().filesApi;
  };

  async upload(file) {
    let formData = new FormData();
    formData.append("directory", `/`);
    formData.append("file1", file);
    const tokenV2 = localStorage.getItem("accessTokenV2");
    const response = await fetch(`${baseUrl}/v2/files`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${tokenV2}`
      },
      body: formData
    });
    return await response.json();
  }
}

class OrdersApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().ordersApi;
  };
}

class OfficeApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().officeApi;
  };
}

class DeviceApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().deviceApi;
  };
}

class CounterApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().counterApi;
  };
}

class UserApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().userApi;
  };
}

// added by sa
class SettingsUserApi {
  constructor() {
    this.initialize();
  }

  initialize = async () => {
    if (!isTokenValidV2()) {
      await refreshTokenV2();
    }
    this.api = RequestManager().settingsUserApi;
  };
}

const refreshTokenV2 = async () => {
  const refreshToken = localStorage.getItem("refreshTokenV2");
  if (refreshToken) {
    const body = {
      refresh: refreshToken
    };

    const path = `${baseUrl}/v2/token/refresh`;
    const response = await fetch(path, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const tokensPair = await response.json();
    localStorage.setItem("accessTokenV2", tokensPair.access);
    localStorage.setItem("refreshTokenV2", tokensPair.refresh);
    eventBus.$emit("token-v2-set");
    webSocketService = new WebSocketService(WEBSOCKET_URL, tokensPair.access);
    webSocketService.initListeners();
  }
};

export {
  OfficeApi,
  TechniqueApi,
  CategoryApi,
  refreshTokenV2,
  OrdersApi,
  FilesApi,
  CompanyApi,
  DeviceApi,
  CounterApi,
  UserApi,
  // added by sa
  SettingsUserApi
};

/**
 * Files API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class FileItem {
    
    constructor(name, path, url) { 
        
        FileItem.initialize(this, name, path, url);
    }

    
    static initialize(obj, name, path, url) { 
        obj['name'] = name;
        obj['path'] = path;
        obj['url'] = url;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FileItem();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('path')) {
                obj['path'] = ApiClient.convertToType(data['path'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}


FileItem.prototype['name'] = undefined;


FileItem.prototype['path'] = undefined;


FileItem.prototype['url'] = undefined;






export default FileItem;


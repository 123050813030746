import RequestManager from "../../../function/request/RequestManager";
export default {
  GET_ALL_OFFICES: ctx => {
    RequestManager()
      .getCompanyOfficesList()
      .then(data => {
        ctx.commit("setOffices", data);
      });
  },
  SET_NEW_OFFICE: (ctx, data) => {
    ctx.commit("setNewOffice", data);
  }
};

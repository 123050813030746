/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Order from './Order';
import OrderHierarchyAllOf from './OrderHierarchyAllOf';
import OrdersList from './OrdersList';
import Permission from './Permission';
import Status from './Status';
import Tag from './Tag';
import Type from './Type';


class OrderHierarchy {
    
    constructor(id, companyId, title, type, typeId, status, statusId, tags, tagIds, chatId, chatNewMessagesCount, createdAt, updatedAt) { 
        Order.initialize(this, id, companyId, title, type, typeId, status, statusId, tags, tagIds, chatId, chatNewMessagesCount, createdAt, updatedAt);OrderHierarchyAllOf.initialize(this);
        OrderHierarchy.initialize(this, id, companyId, title, type, typeId, status, statusId, tags, tagIds, chatId, chatNewMessagesCount, createdAt, updatedAt);
    }

    
    static initialize(obj, id, companyId, title, type, typeId, status, statusId, tags, tagIds, chatId, chatNewMessagesCount, createdAt, updatedAt) { 
        obj['id'] = id;
        obj['companyId'] = companyId;
        obj['title'] = title;
        obj['type'] = type;
        obj['typeId'] = typeId;
        obj['status'] = status;
        obj['statusId'] = statusId;
        obj['tags'] = tags;
        obj['tagIds'] = tagIds;
        obj['chatId'] = chatId;
        obj['chatNewMessagesCount'] = chatNewMessagesCount;
        obj['createdAt'] = createdAt;
        obj['updatedAt'] = updatedAt;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderHierarchy();
            Order.constructFromObject(data, obj);
            OrderHierarchyAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = ApiClient.convertToType(data['permissions'], [Permission]);
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('texts')) {
                obj['texts'] = ApiClient.convertToType(data['texts'], {'String': 'String'});
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [File]);
            }
            if (data.hasOwnProperty('filePaths')) {
                obj['filePaths'] = ApiClient.convertToType(data['filePaths'], ['String']);
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = Type.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('typeId')) {
                obj['typeId'] = ApiClient.convertToType(data['typeId'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = Status.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'String');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [Tag]);
            }
            if (data.hasOwnProperty('tagIds')) {
                obj['tagIds'] = ApiClient.convertToType(data['tagIds'], ['String']);
            }
            if (data.hasOwnProperty('budget')) {
                obj['budget'] = ApiClient.convertToType(data['budget'], 'Number');
            }
            if (data.hasOwnProperty('estimatedTime')) {
                obj['estimatedTime'] = ApiClient.convertToType(data['estimatedTime'], 'Number');
            }
            if (data.hasOwnProperty('deadline')) {
                obj['deadline'] = ApiClient.convertToType(data['deadline'], 'Date');
            }
            if (data.hasOwnProperty('chatId')) {
                obj['chatId'] = ApiClient.convertToType(data['chatId'], 'String');
            }
            if (data.hasOwnProperty('chatNewMessagesCount')) {
                obj['chatNewMessagesCount'] = ApiClient.convertToType(data['chatNewMessagesCount'], 'Number');
            }
            if (data.hasOwnProperty('estimation')) {
                obj['estimation'] = ApiClient.convertToType(data['estimation'], 'Number');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('cost')) {
                obj['cost'] = ApiClient.convertToType(data['cost'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('childs')) {
                obj['childs'] = OrdersList.constructFromObject(data['childs']);
            }
        }
        return obj;
    }


}


OrderHierarchy.prototype['id'] = undefined;


OrderHierarchy.prototype['companyId'] = undefined;


OrderHierarchy.prototype['permissions'] = undefined;


OrderHierarchy.prototype['title'] = undefined;


OrderHierarchy.prototype['texts'] = undefined;


OrderHierarchy.prototype['files'] = undefined;


OrderHierarchy.prototype['filePaths'] = undefined;


OrderHierarchy.prototype['type'] = undefined;


OrderHierarchy.prototype['typeId'] = undefined;


OrderHierarchy.prototype['status'] = undefined;


OrderHierarchy.prototype['priority'] = undefined;


OrderHierarchy.prototype['statusId'] = undefined;


OrderHierarchy.prototype['tags'] = undefined;


OrderHierarchy.prototype['tagIds'] = undefined;


OrderHierarchy.prototype['budget'] = undefined;


OrderHierarchy.prototype['estimatedTime'] = undefined;


OrderHierarchy.prototype['deadline'] = undefined;


OrderHierarchy.prototype['chatId'] = undefined;


OrderHierarchy.prototype['chatNewMessagesCount'] = undefined;


OrderHierarchy.prototype['estimation'] = undefined;


OrderHierarchy.prototype['comment'] = undefined;


OrderHierarchy.prototype['cost'] = undefined;


OrderHierarchy.prototype['createdAt'] = undefined;


OrderHierarchy.prototype['updatedAt'] = undefined;


OrderHierarchy.prototype['childs'] = undefined;


// Implement Order interface:

Order.prototype['id'] = undefined;

Order.prototype['companyId'] = undefined;

Order.prototype['permissions'] = undefined;

Order.prototype['title'] = undefined;

Order.prototype['texts'] = undefined;

Order.prototype['files'] = undefined;

Order.prototype['filePaths'] = undefined;

Order.prototype['type'] = undefined;

Order.prototype['typeId'] = undefined;

Order.prototype['status'] = undefined;

Order.prototype['priority'] = undefined;

Order.prototype['statusId'] = undefined;

Order.prototype['tags'] = undefined;

Order.prototype['tagIds'] = undefined;

Order.prototype['budget'] = undefined;

Order.prototype['estimatedTime'] = undefined;

Order.prototype['deadline'] = undefined;

Order.prototype['chatId'] = undefined;

Order.prototype['chatNewMessagesCount'] = undefined;

Order.prototype['estimation'] = undefined;

Order.prototype['comment'] = undefined;

Order.prototype['cost'] = undefined;

Order.prototype['createdAt'] = undefined;

Order.prototype['updatedAt'] = undefined;
// Implement OrderHierarchyAllOf interface:

OrderHierarchyAllOf.prototype['childs'] = undefined;




export default OrderHierarchy;


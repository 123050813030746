<template>
  <TepmplateBlock mod-class content-class="mytechnique-wrap mt-4">
    <div class="scroll-container">
      <vuescroll>
        <h1 class="company-title">
          Название компании: {{ companyInfo.brand }}
        </h1>
        <div class="company-data">
          <div class="company-data__item">
            <router-link
              :to="getLink('/general-info')"
              class="company-data__item-edit"
            >
              <img
                src="../../resource/img/editIcon.svg"
                alt="edit icon"
                class="company-data__item-img"
              />
              Редактировать
            </router-link>
            <h2 class="company-data__title">
              Общая информация
            </h2>

            <table class="company-table">
              <tbody>
                <tr>
                  <td class="company-table__title">Бренд</td>
                  <td>{{ companyInfo.brand }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">ИНН</td>
                  <td>{{ companyInfo.inn }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">КПП</td>
                  <td>{{ companyInfo.kpp }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">ОРГН</td>
                  <td>{{ companyInfo.ogrn }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">Наименование юр. лица</td>
                  <td>{{ companyInfo.legalEntity }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">Юридический адрес</td>
                  <td>{{ companyInfo.legalEntityAddress }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">Фактический адрес</td>
                  <td>{{ companyInfo.factAddress }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="company-data__item bank-data">
            <router-link
              :to="getLink('/bank-data')"
              class="company-data__item-edit"
            >
              <img
                src="../../resource/img/editIcon.svg"
                alt="edit icon"
                class="company-data__item-img"
              />
              Редактировать
            </router-link>

            <h2 class="company-data__title">
              Банковские данные
            </h2>

            <table class="company-table">
              <tbody>
                <tr>
                  <td class="company-table__title">№ счета</td>
                  <td>{{ companyInfo.bankAccountNum }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">Наименование банка</td>
                  <td>{{ companyInfo.bankName }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">ИНН банка</td>
                  <td>{{ companyInfo.bankInn }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">БИК банка</td>
                  <td>{{ companyInfo.bankBik }}</td>
                </tr>
                <tr>
                  <td class="company-table__title">к/с банка</td>
                  <td>{{ companyInfo.bankKs }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <h2 class="company-title mb-0">Список офисов</h2>
          <IconPlus @clicked="createOffice" />
        </div>

        <div class="my-request">
          <div
            v-for="(item, index) in officesInfo.items"
            :key="index"
            class="my-request__item"
          >
            <router-link
              :to="`/office/${companyInfo.id}/${item.id}`"
              class="company-data__item-chat"
            >
              Редактировать
            </router-link>
            <!-- <router-link
                :to="`/edit-user/${item.id}`"
                class="company-data__item-chat"
              >
                Редактировать
              </router-link> -->

            <img :src="noAva" alt="" class="my-request__img" />

            <h3 class="my-request__title">
              {{ item.name }}
            </h3>
            <p class="my-request__model">{{ item.city }}</p>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <h2 class="company-title mb-0">Список пользователей</h2>
          <IconPlus @clicked="createUser" />
        </div>

        <div class="my-request">
          <div
            v-for="(item, index) in usersInfo.items"
            :key="index"
            class="my-request__item"
          >
            <router-link
              :to="`/edit-user/${item.companyId}/${item.id}`"
              class="company-data__item-chat"
            >
              Редактировать
            </router-link>

            <img
              :src="item.profilePicture !== '' ? item.profilePicture : noAva"
              alt=""
              class="my-request__img"
            />

            <h3 class="my-request__title">
              {{ item.firstName + " " + item.lastName }}
            </h3>
            <p class="my-request__model">{{ item.model }}</p>
          </div>

          <!-- goToEdit(user) {
              this.$router.push({
                name: "EditUser",
                params: {
                  id: user.id
                }
              });
            }, -->

          <!-- <router-link
              :to="'/user-list/' + companyInfo.id"
              class="company-data__item-link"
            > 
              Посмотреть весь список &#8594;
            </router-link>-->
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <h2 class="company-title mb-0">Список техники</h2>
          <IconPlus @clicked="createTechnique" />
        </div>

        <div class="mytechnique row">
          <div
            v-for="(item, index) in devicesInfo.items"
            :key="index"
            class="mytechnique__item_wr"
          >
            <div class="mytechnique__item">
              <div>
                <!-- <router-link class="mytechnique__link" to="/"> -->
                <router-link
                  :to="`/my-techique/${item.id}`"
                  class="company-data__item-chat"
                >
                  Просмотреть
                </router-link>
                <img
                  style="width:90px;height:80px"
                  :src="
                    item.picturePath
                      ? item.picturePath
                      : 'https://placehold.it/90x80'
                  "
                  alt=""
                  class="mytechnique__img"
                />

                <h3 class="mytechnique__title">{{ item.title }}</h3>
                <p class="mytechnique__model">{{ item.serialNum }}</p>
                <p class="mytechnique__model">{{ item.stickerNum }}</p>
                <p class="mytechnique__model">
                  Офис номер: {{ item.officeId }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </vuescroll>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "@component/TepmplateBlock";
import IconPlus from "../../components/Form/IconPlus";
import {
  CompanyApi,
  DeviceApi,
  OfficeApi,
  UserApi
} from "../../function/request/apiV2";
//import { GET_MAIN_COMPANY_INFO, GET_BANK_INFO } from "../../store/types";
import vuescroll from "vuescroll";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "CompanyPage",
  components: { TepmplateBlock, vuescroll, IconPlus },
  computed: {
    ...mapGetters(["currentUserV2", "mainCompanyInfo"])
  },
  data() {
    return {
      active: false,
      companyInfo: {},
      devicesInfo: {},
      officesInfo: {},
      usersInfo: {},
      companyApi: new CompanyApi(),
      deviceApi: new DeviceApi(),
      officeApi: new OfficeApi(),
      userApi: new UserApi(),
      noAva: "https://placehold.it/51x51"
    };
  },
  async mounted() {
    console.log("async mounted()");
    console.log("ids: this.$route.params.id = " + this.$route.params.id);
    const { items } = await this.companyApi.api.listCompanies({
      ids: [this.$route.params.id],
      limit: 1000000,
      sort: "id"
    });
    this.companyInfo = items[0];
    console.log(this.companyInfo);

    const itemsOffices = await this.officeApi.api.listOffice({
      companyIds: [this.$route.params.id],
      limit: 1000000,
      sort: "name"
    });
    this.officesInfo = itemsOffices;
    console.log(itemsOffices);

    const itemsUsers = await this.userApi.api.listUsers({
      companyId: this.$route.params.id,
      limit: 1000000
    });
    this.usersInfo = itemsUsers;
    console.log(itemsUsers);

    const itemsDevices = await this.deviceApi.api.listDevices({
      companyId: this.$route.params.id,
      limit: 1000000,
      sort: "stickerNum"
    });
    this.devicesInfo = itemsDevices;
    console.log(itemsDevices);
  },
  async created() {
    console.log(this.mainCompanyInfo);
  },
  methods: {
    ...mapActions(["GET_MAIN_COMPANY_INFO", "GET_BANK_INFO"]),

    goToEdit(user) {
      this.$router.push({
        name: "EditUser",
        params: {
          id: user.id
        }
      });
    },
    getLink(link) {
      return link + "/" + this.$route.params.id;
    },
    getStatusClass(status) {
      switch (status) {
        case "1":
          return "mytechnique__status_green";
        case "3":
          return "mytechnique__status_blue";
        case "2":
          return "mytechnique__status_orange";
        default:
          return "mytechnique__status_orange";
      }
    },
    getStatus(status) {
      switch (status) {
        case "0":
          return "Уст-во работает";
        case "1":
          return "На обслуживании";
        case "2":
          return "В обработке";
        default:
          return "В обработке";
      }
    },
    createTechnique() {
      if (!this.isCheckedMode) {
        this.$router.push({ name: "NewItems" });
      } else {
        this.$refs.refCreateOrder.showModal();
      }
    },
    createUser() {
      if (!this.isCheckedMode) {
        this.$router.push({ path: "/create-user/" + this.companyInfo.id });
      } else {
        this.$refs.refCreateOrder.showModal();
      }
    },
    createOffice() {
      if (!this.isCheckedMode) {
        this.$router.push({ path: "/office/" + this.companyInfo.id + "/0" });
      } else {
        this.$refs.refCreateOrder.showModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.help-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.scroll-container {
  width: 100% !important;
  height: 85vh;
  margin: 0 auto;
  overflow: hidden;
}
.company-title {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 39px;
  color: #4b4b67;
  text-align: center;
}

.company-data {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  width: 100%;
  min-height: 270px;
  // height: 100%;
  margin-bottom: 45px;
}

.company-data__item-edit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 0;
}

.company-data__item-chat {
  align-self: start;
  padding: 10px;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.04em;

  color: #51a2be;
}

.company-data__item-link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.04em;

  color: #51a2be;
  width: 95%;
}

.company-data__item {
  position: relative;
  max-width: 48%;
  width: 100%;
  height: 100%;
  padding: 22px 30px;

  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
.company-data__item.bank-data {
  height: 270px;
}

.company-data__title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.company-table {
  border: 0;
  width: 100%;
  // height: 100%;
  text-align: left;
  border-collapse: collapse;
}

.company-table td,
.company-table th {
  border: 1px solid #ffffff;
  padding: 4px 1px;
}

.company-table tbody td {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
}

.company-table__title {
  font-weight: 600 !important;
  width: 55%;
}

.my-request {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
}

.my-request__item {
  position: relative;
  min-height: 136px;
  width: 150px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.my-request__title {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #4b4b67;
}

.my-request__img {
  width: 51px;
  height: 51px;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
  border-radius: 50%;
}

.my-request__model {
  margin: 0;
  margin-bottom: 17px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;
}

.mytechnique {
  margin-bottom: 100px;
  &__item_wr {
    margin-right: 16px;
  }
}

.mytechnique__item {
  margin-bottom: 20px;
  min-height: 220px;
  width: 150px;
  padding: 28px 8px 11px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  // &:not(:last-child) {
  //   // margin-right: 16px;
  // }
}

.mytechnique__title {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.mytechnique__img {
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
}

.mytechnique__model {
  margin: 0;
  margin-bottom: 17px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;
}

.mytechnique__status {
  position: relative;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;

  text-align: center;

  // span::before {
  //   content: "";
  //   position: absolute;
  //   top: 2.5px;
  //   left: 9px;
  //   width: 7px;
  //   height: 7px;
  //   border-radius: 50%;
  // }

  &_orange {
    color: #fcbc04;

    span::before {
      background-color: #fcbc04;
    }
  }

  &_green {
    color: #4cac44;

    span::before {
      background-color: #4cac44;
    }
  }

  &_blue {
    color: #1c74fc;

    span::before {
      background-color: #1c74fc;
    }
  }
}

.plus-btn {
  position: absolute;
  bottom: 45px;
  right: 36px;

  display: block;

  width: 80px;
  height: 80px;

  background: #006b90;
  border-radius: 50%;

  font-size: 0;

  border: 0;

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;

    width: 50px;
    height: 50px;

    background-image: url("../../resource/img/plus-big.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover,
  &:focus {
    background: lighten(#006b90, 10%);
  }
}
</style>

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  events: [],
  original: [],
  ready: false
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

import Config from "./Config";
import { getVersion } from "./utils";

export default class AutoReload {
  constructor(options) {
    this.router = options.router;
    this.vm = options.vm;
    this.config = new Config(options.config);

    this.lastVersion = null;

    this.timer = null;
  }

  async init() {
    const config = this.config;

    if (config.Enabled) {
      this.lastVersion = await getVersion();

      if (this.lastVersion && config.CheckInterval > 0) {
        this.timer = setInterval(async () => {
          this.check();
        }, config.CheckInterval * 1000);
      }

      this.router.beforeEach(async (to, from, next) => {
        await this.check(this.router.resolve(to).href);
        next();
      });
    }
  }

  async check(href) {
    const version = await getVersion();

    if (this.lastVersion.BuildVersion != version.BuildVersion) {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      if (this.config.Notification) {
        this.vm.$bvModal
          .msgBoxOk(this.config.NotificationMessage)
          .then(async () => {
            this.lastVersion = await getVersion();
            this.reload(href);
          });
      }
    }
  }

  reload(href) {
    if (href) {
      window.location.href = href;
    } else {
      window.location.reload(true);
    }
  }
}

<template>
  <div :class="isInlineBlock ? 'd-inline' : ''" v-if="this.isShowInformation">
    <span :id="generatedId" :class="[getClass, checkBackground]">?</span>

    <b-popover triggers="hover" :target="generatedId" placement="top">
      <div class="custom-popover__body">{{ message }}</div>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HelpPopover",
  props: {
    message: {
      type: String,
      default: "popover"
    },
    variant: {
      type: Number,
      default: 1
    },
    isInlineBlock: {
      type: Boolean,
      default: true
    },
    bgWhite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      generatedId: Math.random()
        .toString(36)
        .substr(2, 9)
    };
  },
  computed: {
    ...mapGetters(["isShowInformation"]),
    getClass() {
      let labelVar;
      switch (this.variant) {
        case 1:
          labelVar = "custom-popover__variant-1";
          break;
        case 2:
          labelVar = "custom-popover__variant-2";
          break;
        default:
          labelVar = "custom-popover__variant-1";
      }
      return labelVar;
    },
    checkBackground() {
      return this.bgWhite ? "bg-white" : "";
    }
  }
};
</script>

<style scoped lang="scss">
.custom-popover__variant-1 {
  cursor: help;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: var(--help-popover-color);
  border-radius: 50%;
  color: white;
  font-size: 12px;
  letter-spacing: 0;
}

.custom-popover__variant-2 {
  cursor: help;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--main-text-color);
  border-radius: 2px;
  border: 1px #006b90 solid;
  color: #006b90;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
}

.custom-popover__body {
  padding: 5px 10px;
}

.bg-white {
  background-color: white !important;
  color: #006b90 !important;
}
</style>

/**
 * Office API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Office from './Office';


class OfficeList {
    
    constructor(items, count) { 
        
        OfficeList.initialize(this, items, count);
    }

    
    static initialize(obj, items, count) { 
        obj['items'] = items;
        obj['count'] = count;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OfficeList();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Office]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }


}


OfficeList.prototype['items'] = undefined;


OfficeList.prototype['count'] = undefined;






export default OfficeList;


import RequestManager from "../../../function/request/RequestManager";
import { SET_ROLE } from "../../types";
export default {
  GET_USER_ROLES: ctx => {
    RequestManager()
      .getUserRoles()
      .then(data => {
        const roles = data.roles;
        for (let key in roles) {
          if (roles[key] === "crm_admin" || roles[key] === "crm_manager") {
            delete roles[key];
          }
        }
        ctx.commit("setUserRoles", roles);
      });
  },
  async GET_CURRENT_USER({ commit, dispatch }) {
    const user = await RequestManager().getCurrentUser();
    if (user.logError && user.logError.response.status === 401) {
      dispatch("logout");
      return { err: true };
    } else {
      commit("setCurrentUser", user);

      window.localStorage.setItem("currentUser", JSON.stringify(user));

      dispatch(SET_ROLE, user.role);
      dispatch("GET_ALL_OFFICES");
      dispatch("devices/GET_DEVICES_STATUS");
      dispatch("devices/GET_DEVICES_TYPES");
      dispatch("calendar/getEvents");
      dispatch("GET_ALL_TAGS");
      dispatch("GET_MAIN_COMPANY_INFO");
      dispatch("GET_BANK_INFO");

      return { err: false };
    }
  },
  SET_CURRENT_USER: (ctx, user) => {
    ctx.commit("setCurrentUser", user);
  },
  async GET_CURRENT_USER_V2({ commit }) {
    const user = await RequestManager().userApi.getCurrentUser();
    if (!user.lastLogin) {
      RequestManager().userApi.lastLoginUser(user.id, {
        lastLogin: new Date().toISOString()
      });
    }
    commit("setCurrentUserV2", user);
  },
  SET_ADMIN_PROFILE({ commit }, adminProfile) {
    commit("setAdminProfile", adminProfile);
  }
};

/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';


class Permission {
    
    constructor(user, userId, roles) { 
        
        Permission.initialize(this, user, userId, roles);
    }

    
    static initialize(obj, user, userId, roles) { 
        obj['user'] = user;
        obj['userId'] = userId;
        obj['roles'] = roles;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Permission();

            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], ['String']);
            }
        }
        return obj;
    }


}


Permission.prototype['user'] = undefined;


Permission.prototype['userId'] = undefined;


Permission.prototype['roles'] = undefined;






Permission['RolesEnum'] = {

    
    "owner": "owner",

    
    "canRead": "canRead",

    
    "canWrite": "canWrite",

    
    "viewer": "viewer",

    
    "performer": "performer"
};



export default Permission;


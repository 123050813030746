/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class DeviceShort {
    
    constructor() { 
        
        DeviceShort.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceShort();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('serialNum')) {
                obj['serialNum'] = ApiClient.convertToType(data['serialNum'], 'String');
            }
            if (data.hasOwnProperty('specialMarks')) {
                obj['specialMarks'] = ApiClient.convertToType(data['specialMarks'], 'String');
            }
            if (data.hasOwnProperty('stickerNum')) {
                obj['stickerNum'] = ApiClient.convertToType(data['stickerNum'], 'String');
            }
            if (data.hasOwnProperty('stockNum')) {
                obj['stockNum'] = ApiClient.convertToType(data['stockNum'], 'String');
            }
            if (data.hasOwnProperty('picturePath')) {
                obj['picturePath'] = ApiClient.convertToType(data['picturePath'], 'String');
            }
            if (data.hasOwnProperty('pictureUrl')) {
                obj['pictureUrl'] = ApiClient.convertToType(data['pictureUrl'], 'String');
            }
        }
        return obj;
    }


}


DeviceShort.prototype['id'] = undefined;


DeviceShort.prototype['type'] = undefined;


DeviceShort.prototype['status'] = undefined;


DeviceShort.prototype['serialNum'] = undefined;


DeviceShort.prototype['specialMarks'] = undefined;


DeviceShort.prototype['stickerNum'] = undefined;


DeviceShort.prototype['stockNum'] = undefined;


DeviceShort.prototype['picturePath'] = undefined;


DeviceShort.prototype['pictureUrl'] = undefined;






DeviceShort['TypeEnum'] = {

    
    "printer": "printer",

    
    "scanner": "scanner",

    
    "copier": "copier",

    
    "mfp": "mfp"
};



DeviceShort['StatusEnum'] = {

    
    "working": "working",

    
    "processing": "processing",

    
    "servicing": "servicing",

    
    "decommission": "decommission"
};



export default DeviceShort;


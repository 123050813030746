export default {
  setDevicesTypes: (state, data) => {
    state.devicesTypes = data.types;
  },
  setDevicesStatus: (state, data) => {
    state.devicesStatus = data.status;
  },
  setNewDevice: (state, device) => {
    state.devices = [...state.devices, device];
  },
  setDevice(state, device) {
    state.currentDevice = device;
  },
  deleteTag(state, tags) {
    state.currentDevice.tags = tags;
  }
};

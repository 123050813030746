/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BaseTechnique from './BaseTechnique';
import Parameter from './Parameter';
import TechniqueEditAllOf from './TechniqueEditAllOf';


class TechniqueEdit {
    
    constructor(title, description, parameters, categoryId) { 
        BaseTechnique.initialize(this, title, description, parameters, categoryId);TechniqueEditAllOf.initialize(this);
        TechniqueEdit.initialize(this, title, description, parameters, categoryId);
    }

    
    static initialize(obj, title, description, parameters, categoryId) { 
        obj['title'] = title;
        obj['description'] = description;
        obj['parameters'] = parameters;
        obj['categoryId'] = categoryId;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueEdit();
            BaseTechnique.constructFromObject(data, obj);
            TechniqueEditAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [Parameter]);
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], ['String']);
            }
        }
        return obj;
    }


}


TechniqueEdit.prototype['title'] = undefined;


TechniqueEdit.prototype['description'] = undefined;


TechniqueEdit.prototype['parameters'] = undefined;


TechniqueEdit.prototype['categoryId'] = undefined;


TechniqueEdit.prototype['files'] = undefined;


// Implement BaseTechnique interface:

BaseTechnique.prototype['title'] = undefined;

BaseTechnique.prototype['description'] = undefined;

BaseTechnique.prototype['parameters'] = undefined;

BaseTechnique.prototype['categoryId'] = undefined;
// Implement TechniqueEditAllOf interface:

TechniqueEditAllOf.prototype['files'] = undefined;




export default TechniqueEdit;


import { isBoolean } from "./utils";
import { VERSION_CHECK_MESSAGE } from "@/app-config";

export default class Config {
  constructor(origin) {
    /**
     * Флаг включения модуля
     * @type {Boolean}
     */
    this.Enabled = isBoolean(origin.Enabled) ? origin.Enabled : true;

    /**
     * Интервал проверки в секундах
     * @type {Number}
     */
    this.CheckInterval = origin.CheckInterval;

    /**
     * Флаг показа уведомления
     * @type {Boolean}
     */
    this.Notification = isBoolean(origin.Notification)
      ? origin.Notification
      : true;

    /**
     * Текст уведомления
     * @type {String}
     */
    this.NotificationMessage =
      origin.NotificationMessage ?? VERSION_CHECK_MESSAGE;
  }
}

/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Device from '../model/Device';
import DeviceList from '../model/DeviceList';
import HistoryList from '../model/HistoryList';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse2002 from '../model/InlineResponse2002';


export default class DeviceApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createDeviceWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/devices', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createDevice(opts) {
      return this.createDeviceWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteDeviceWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/device/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteDevice(id) {
      return this.deleteDeviceWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceStatusListWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/devices/status-list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceStatusList() {
      return this.deviceStatusListWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceTagListWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;
      return this.apiClient.callApi(
        '/devices/tag-list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceTagList() {
      return this.deviceTagListWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceTypeListWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/devices/type-list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceTypeList() {
      return this.deviceTypeListWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    devicesExportCsvWithHttpInfo(companyId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'companyId' is set
      if (companyId === undefined || companyId === null) {
        throw new Error("Missing the required parameter 'companyId' when calling devicesExportCsv");
      }

      let pathParams = {
      };
      let queryParams = {
        'companyId': companyId,
        'separator': opts['separator'],
        'enclosure': opts['enclosure'],
        'escape': opts['escape'],
        'encoding': opts['encoding']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/devices/export/csv', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    devicesExportCsv(companyId, opts) {
      return this.devicesExportCsvWithHttpInfo(companyId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editDeviceWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/device/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editDevice(id, opts) {
      return this.editDeviceWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getDeviceWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/device/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getDevice(id) {
      return this.getDeviceWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getDeviceHistoryWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDeviceHistory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'type': opts['type'],
        'offset': opts['offset'],
        'limit': opts['limit'],
        'order': opts['order']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = HistoryList;
      return this.apiClient.callApi(
        '/device/{id}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getDeviceHistory(id, opts) {
      return this.getDeviceHistoryWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    listDevicesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'csv'),
        'companyId': opts['companyId'],
        'officeIds': this.apiClient.buildCollectionParam(opts['officeIds'], 'csv'),
        'locationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'csv'),
        'techniqueIds': this.apiClient.buildCollectionParam(opts['techniqueIds'], 'csv'),
        'serialNum': opts['serialNum'],
        'title': opts['title'],
        'stickerNum': opts['stickerNum'],
        'stockNum': opts['stockNum'],
        'name': opts['name'],
        'type': opts['type'],
        'status': opts['status'],
        'statuses': this.apiClient.buildCollectionParam(opts['statuses'], 'csv'),
        'tagIds': this.apiClient.buildCollectionParam(opts['tagIds'], 'csv'),
        'countersSum': this.apiClient.buildCollectionParam(opts['countersSum'], 'csv'),
        'countersSumMin': opts['countersSumMin'],
        'countersSumMax': opts['countersSumMax'],
        'costPerPageMin': opts['costPerPageMin'],
        'costPerPageMax': opts['costPerPageMax'],
        'costPerPageColorMin': opts['costPerPageColorMin'],
        'costPerPageColorMax': opts['costPerPageColorMax'],
        'expensesMin': opts['expensesMin'],
        'expensesMax': opts['expensesMax'],
        'offset': opts['offset'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'order': opts['order']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DeviceList;
      return this.apiClient.callApi(
        '/devices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    listDevices(opts) {
      return this.listDevicesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

export default {
  userRoles(state) {
    return state.userRoles;
  },
  currentUser(state) {
    return state.currentUser;
  },
  currentUserV2(state) {
    return state.currentUserV2;
  },
  adminProfile(state) {
    return state.adminProfile;
  }
};

/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CategoryParameter {
    
    constructor(code, title, objectType, required) { 
        
        CategoryParameter.initialize(this, code, title, objectType, required);
    }

    
    static initialize(obj, code, title, objectType, required) { 
        obj['code'] = code;
        obj['title'] = title;
        obj['objectType'] = objectType;
        obj['required'] = required || false;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CategoryParameter();

            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('objectType')) {
                obj['objectType'] = ApiClient.convertToType(data['objectType'], 'String');
            }
            if (data.hasOwnProperty('required')) {
                obj['required'] = ApiClient.convertToType(data['required'], 'Boolean');
            }
            if (data.hasOwnProperty('parent')) {
                obj['parent'] = ApiClient.convertToType(data['parent'], 'String');
            }
        }
        return obj;
    }


}


CategoryParameter.prototype['code'] = undefined;


CategoryParameter.prototype['title'] = undefined;


CategoryParameter.prototype['description'] = undefined;


CategoryParameter.prototype['objectType'] = undefined;


CategoryParameter.prototype['required'] = false;


CategoryParameter.prototype['parent'] = undefined;






CategoryParameter['ObjectTypeEnum'] = {

    
    "CategoryStringParameter": "CategoryStringParameter",

    
    "CategoryIntParameter": "CategoryIntParameter",

    
    "CategoryFloatParameter": "CategoryFloatParameter",

    
    "CategoryBoolParameter": "CategoryBoolParameter",

    
    "CategoryGroupParameter": "CategoryGroupParameter"
};



export default CategoryParameter;


import RequestManager from "../../../function/request/RequestManager";
export default {
  GET_MAIN_COMPANY_INFO: ctx => {
    RequestManager()
      .getMainCompanyInfo()
      .then(data => {
        ctx.commit("setMainCompanyInfo", data);
      });
  }
};

/**
 * Company API
 * Used for: - Manage Company 
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Manager {
    
    constructor(email) { 
        
        Manager.initialize(this, email);
    }

    
    static initialize(obj, email) { 
        obj['email'] = email;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Manager();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('profilePicture')) {
                obj['profilePicture'] = ApiClient.convertToType(data['profilePicture'], 'String');
            }
        }
        return obj;
    }


}


Manager.prototype['id'] = undefined;


Manager.prototype['firstName'] = undefined;


Manager.prototype['lastName'] = undefined;


Manager.prototype['email'] = undefined;


Manager.prototype['phone'] = undefined;


Manager.prototype['profilePicture'] = undefined;






export default Manager;


/**
 * OFPR-Shop API
 * Used for: - Manage Product - Manage Order 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Status {
    
    constructor(status) { 
        
        Status.initialize(this, status);
    }

    
    static initialize(obj, status) { 
        obj['status'] = status;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Status();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
        }
        return obj;
    }


}


Status.prototype['status'] = undefined;






Status['StatusEnum'] = {

    
    "Open": "Open",

    
    "In processing": "In processing",

    
    "Completed": "Completed",

    
    "Canceled": "Canceled"
};



export default Status;


/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Parameter {
    
    constructor(code, objectType) { 
        
        Parameter.initialize(this, code, objectType);
    }

    
    static initialize(obj, code, objectType) { 
        obj['code'] = code;
        obj['objectType'] = objectType;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Parameter();

            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('objectType')) {
                obj['objectType'] = ApiClient.convertToType(data['objectType'], 'String');
            }
        }
        return obj;
    }


}


Parameter.prototype['code'] = undefined;


Parameter.prototype['objectType'] = undefined;






Parameter['ObjectTypeEnum'] = {

    
    "StringParameter": "StringParameter",

    
    "IntParameter": "IntParameter",

    
    "FloatParameter": "FloatParameter",

    
    "BoolParameter": "BoolParameter"
};



export default Parameter;


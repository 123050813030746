/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';


class ChangePermissionsHistoryAllOf {
    
    constructor(user, action) { 
        
        ChangePermissionsHistoryAllOf.initialize(this, user, action);
    }

    
    static initialize(obj, user, action) { 
        obj['user'] = user;
        obj['action'] = action;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangePermissionsHistoryAllOf();

            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
        }
        return obj;
    }


}


ChangePermissionsHistoryAllOf.prototype['user'] = undefined;


ChangePermissionsHistoryAllOf.prototype['action'] = undefined;


ChangePermissionsHistoryAllOf.prototype['role'] = undefined;






ChangePermissionsHistoryAllOf['ActionEnum'] = {

    
    "add": "add",

    
    "remove": "remove",

    
    "newRole": "newRole",

    
    "removeRole": "removeRole"
};



export default ChangePermissionsHistoryAllOf;


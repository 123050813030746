import eventBus from "@/utils/event-bus";

class WebSocketService {
  constructor(connectionUrl, jwt) {
    this.connectionUrl = connectionUrl;
    this.jwt = jwt;
    this.socket = new WebSocket(this.connectionUrl);
  }

  getInstance() {
    return this.socket;
  }

  initListeners() {
    this.socket.onopen = event => {
      console.log("WebSocket connection opened:", event);
      this.socket.send(
        JSON.stringify({
          type: "auth",
          payload: {
            jwt: this.jwt
          }
        })
      );
    };

    this.socket.onmessage = event => {
      console.log(event.data);
      const message = JSON.parse(event.data);
      console.log(message);
      if (
        message.type === "notification" &&
        message.payload.event === "chat.new_message"
      ) {
        eventBus.$emit("chat_new-message", message.payload);
        if (
          message.payload.payload.metadata &&
          message.payload.payload.metadata.refs[0].type === "order"
        ) {
          eventBus.$emit(
            "order_new-message",
            message.payload.payload.metadata.refs[0].id
          );
        }
      }
      if (
        message.type === "notification" &&
        message.payload.event === "site_notification"
      ) {
        eventBus.$emit("site_notification", message.payload);
      }
    };

    this.socket.onerror = error => {
      console.log("WebSocket error:", error);
    };

    this.socket.onclose = event => {
      console.log("WebSocket connection closed:", event.code);
      this.socket = null;
      this.socket = new WebSocket(this.connectionUrl);
      this.initListeners();
    };
  }

  close = () => {
    this.socket.close();
  };
}

export default WebSocketService;

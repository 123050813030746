/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Access from './Access';


class UserCreate {
    
    constructor(password, email, role) { 
        
        UserCreate.initialize(this, password, email, role);
    }

    
    static initialize(obj, password, email, role) { 
        obj['password'] = password;
        obj['email'] = email;
        obj['role'] = role;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCreate();

            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('profilePicture')) {
                obj['profilePicture'] = ApiClient.convertToType(data['profilePicture'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Number');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'Number');
            }
            if (data.hasOwnProperty('access')) {
                obj['access'] = Access.constructFromObject(data['access']);
            }
        }
        return obj;
    }


}


UserCreate.prototype['password'] = undefined;


UserCreate.prototype['email'] = undefined;


UserCreate.prototype['phone'] = undefined;


UserCreate.prototype['firstName'] = undefined;


UserCreate.prototype['lastName'] = undefined;


UserCreate.prototype['profilePicture'] = undefined;


UserCreate.prototype['role'] = undefined;


UserCreate.prototype['companyId'] = undefined;


UserCreate.prototype['officeId'] = undefined;


UserCreate.prototype['access'] = undefined;






export default UserCreate;


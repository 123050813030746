import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import CompanyPage from "@page/Base/saCompanyInfo";
import { isTokenValid } from "../function/checkExpiredToken";

const baseFolder = "Base";
const userFolder = "User";

Vue.use(VueRouter);

const AsyncComponent = (name, folder = null) => {
  if (folder) {
    return () => import(`../views/${folder}/${name}`);
  } else {
    return () => import(`../views/${name}`);
  }
};

const isAuth = (to, from, next) => {
  if (store.getters.getAuth.isAuth || isTokenValid()) {
    next();
    return;
  }
  if (to.path) {
    next({ name: "Auth", query: { redirect: to.path } });
  } else {
    next("/auth");
  }
};

const ifAdmin = (to, from, next) => {
  if (store.getters.getAuth.isAuth || isTokenValid()) {
    if (
      store.getters.getAuth.role === "3" ||
      store.getters.getAuth.role === "2" ||
      store.getters.getAuth.role === "1"
    ) {
      next();
      return;
    }
  }
  next("/auth");
};

const ifManager = (to, from, next) => {
  if (store.getters.getAuth.isAuth || isTokenValid()) {
    if (store.getters.getAuth.role === 4) {
      next(from.path);
      return;
    }
    next();
    return;
  }
  next("/auth");
};

const isCorrectRole = (to, from, next) => {
  if (store.getters.getAuth.isAuth) {
    if (store.getters.getAuth.role == 1 || store.getters.getAuth.role == 2) {
      next();
      return;
    }
  }
  next("/auth");
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: AsyncComponent("Home")
  },
  {
    path: "/registration",
    name: "Registration",
    component: AsyncComponent("Registration", userFolder)
  },
  {
    path: "/auth",
    name: "Auth",
    component: AsyncComponent("Auth", userFolder)
  },
  {
    path: "/recovery-password",
    name: "Recovery",
    component: AsyncComponent("RecoveryPassword", userFolder)
  },
  {
    path: "/main",
    name: "Main",
    component: AsyncComponent("Main"),
    beforeEnter: isAuth
  },
  {
    path: "/my-techique",
    name: "MyTechnique",
    component: AsyncComponent("MyTechnique", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/my-techique/:id",
    name: "TehniquePage",
    component: AsyncComponent("TehniquePage", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/my-techique/:id/edit",
    name: "EditTehniquePage",
    component: AsyncComponent("EditTehniquePage", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/add-new-item",
    name: "NewItems",
    component: AsyncComponent("NewItems", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/chats",
    name: "Chats",
    component: AsyncComponent("UsersChat", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/my-request",
    name: "MyRequest",
    component: AsyncComponent("MyRequest", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/news",
    name: "NewsList",
    component: AsyncComponent("NewsList", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/news-create",
    name: "NewsCreate",
    component: AsyncComponent("NewsCreate", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/request-create",
    name: "RequestCreate",
    component: AsyncComponent("OrdersCreate", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/qrcodeorder",
    name: "RequestCreateUnregistered",
    component: AsyncComponent("OrdersCreateUnregistered", baseFolder)
  },
  {
    path: "/qrcodeorder-neopharm",
    name: "RequestCreateNeopharm",
    component: AsyncComponent("OrdersCreateNeopharm", baseFolder)
  },
  {
    path: "qrcodeorder/message",
    name: "OrderWasCreateMessage",
    component: AsyncComponent("OrderWasCreated", baseFolder)
  },
  {
    path: "/request-list",
    name: "RequestList",
    component: AsyncComponent("OrdersListV2", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/order/:id",
    name: "Order",
    component: AsyncComponent("Order", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/instruction",
    name: "Instruction",
    component: AsyncComponent("Instruction", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/company-info/:id",
    name: "CompanyPage",
    component: CompanyPage,
    beforeEnter: isCorrectRole
  },
  {
    path: "/offices",
    name: "OfficesList",
    component: AsyncComponent("OfficesList", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/manager/chat",
    name: "ManagerChat",
    component: AsyncComponent("Chat", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/my-request/chat/:id",
    name: "RequestChat",
    component: AsyncComponent("Chat", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: AsyncComponent("MyProfile", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/user-list",
    name: "UserList",
    component: AsyncComponent("UserList", baseFolder),
    beforeEnter: isAuth
  },
  {
    path: "/edit-user/:companyId/:id",
    name: "EditUser",
    component: AsyncComponent("EditUser", userFolder),
    beforeEnter: ifAdmin
  },
  {
    path: "/calendar",
    name: "MyCalendar",
    component: AsyncComponent("MyCalendar"),
    beforeEnter: isAuth
  },
  {
    path: "/general-info/:id",
    name: "GeneralInfo",
    component: AsyncComponent("GeneralInfo", userFolder),
    beforeEnter: ifAdmin
  },
  {
    path: "/bank-data/:id",
    name: "BankData",
    component: AsyncComponent("BankingData", userFolder),
    beforeEnter: ifAdmin
  },
  {
    path: "/office/:companyId/:id",
    name: "OfficeInfo",
    component: AsyncComponent("OfficeInfo", userFolder),
    beforeEnter: ifAdmin
  },
  {
    path: "/admin-profile",
    name: "AdminProfile",
    component: AsyncComponent("AdminProfile", userFolder),
    beforeEnter: ifAdmin
  },

  // {
  //   path: "/admin-profile-intro-registration",
  //   name: "AdminProfileIntroRegistration",
  //   component: AsyncComponent("AdminProfileIntroRegistration", userFolder),
  //   beforeEnter: ifAdmin
  // },

  {
    path: "/create-user/:companyID",
    name: "CreateUser",
    component: AsyncComponent("CreateUser", userFolder),
    beforeEnter: ifAdmin
  },
  {
    path: "/manager-profile",
    name: "ManagerProfile",
    component: AsyncComponent("ManagerProfile", userFolder),
    beforeEnter: ifManager
  },
  {
    path: "/colleagues-contacts",
    name: "ColleaguesContacts",
    component: AsyncComponent("ColleaguesContacts", userFolder),
    beforeEnter: isAuth
  },
  {
    path: "/superadmin",
    name: "superadmin",
    component: AsyncComponent("SuperAdmin", userFolder),
    beforeEnter: isAuth
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/shop",
    name: "Shop",
    component: AsyncComponent("Shop", baseFolder)
  },
  {
    path: "/shop/order",
    name: "ShopMyOrders",
    component: AsyncComponent("ShopMyOrders", baseFolder)
  },
  {
    path: "/shop/message",
    name: "OrderWasCreate",
    component: AsyncComponent("OrderWasCreated", baseFolder)
  },
  {
    path: "/shop/:id",
    name: "ShopProductPage",
    component: AsyncComponent("ShopProduct", baseFolder)
  },
  {
    path: "/cart",
    name: "ShopCart",
    component: AsyncComponent("ShopCart", baseFolder)
  },
  {
    path: "/contacts",
    name: "ShopContacts",
    component: AsyncComponent("ShopContacts", baseFolder)
  },
  {
    path: "/order-payment",
    name: "OrderPayment",
    component: AsyncComponent("ShopOrderPayment", baseFolder)
  },
  {
    path: "/error",
    name: "Error",
    component: AsyncComponent("Error")
  },
  {
    path: "*",
    redirect: "/error"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((from, to, next) => {
  if (to.fullPath.length > 1 && from.fullPath === "/") {
    window.location.href = "/";
  }
  next();
});

export default router;

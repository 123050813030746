export const highlight = (stringToSearch, searchTerm) => {
  if (!searchTerm || searchTerm === "" || !stringToSearch)
    return stringToSearch;
  var iQuery = new RegExp(searchTerm, "ig");
  return stringToSearch.replace(
    iQuery,
    matchedText => `<span class="highlight">${matchedText}</span>`
  );
};

export const dateTimeFromDateString = dateString => {
  try {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "-"
      : `${date.toLocaleDateString("ru-RU")} ${date.toLocaleTimeString(
          ["ru-RU"],
          {
            hour: "2-digit",
            minute: "2-digit"
            // second: "2-digit"
          }
        )}`;
  } catch (error) {
    return "";
  }
};

export const dateTimeWithoutLocale = dateString => {
  try {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "-"
      : new Intl.DateTimeFormat("ru", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        }).format(date);
  } catch (error) {
    return "";
  }
};

export const money = price => {
  const formatterRu = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB"
  });
  if (!price.toString()) {
    return "";
  } else {
    return formatterRu.format(price);
  }
};

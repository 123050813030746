/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Counter {
    
    constructor(codeName, value) { 
        
        Counter.initialize(this, codeName, value);
    }

    
    static initialize(obj, codeName, value) { 
        obj['codeName'] = codeName;
        obj['value'] = value;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Counter();

            if (data.hasOwnProperty('codeName')) {
                obj['codeName'] = ApiClient.convertToType(data['codeName'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'Number');
            }
        }
        return obj;
    }


}


Counter.prototype['codeName'] = undefined;


Counter.prototype['value'] = undefined;






export default Counter;


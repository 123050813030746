/**
 * Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Setting from '../model/Setting';
import SettingList from '../model/SettingList';


export default class UserApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    deleteUserSettingsWithHttpInfo(id, settings) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteUserSettings");
      }
      // verify the required parameter 'settings' is set
      if (settings === undefined || settings === null) {
        throw new Error("Missing the required parameter 'settings' when calling deleteUserSettings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'settings': this.apiClient.buildCollectionParam(settings, 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/settings/user/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteUserSettings(id, settings) {
      return this.deleteUserSettingsWithHttpInfo(id, settings)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editUserSettingsWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editUserSettings");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling editUserSettings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SettingList;
      return this.apiClient.callApi(
        '/settings/user/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editUserSettings(id, body) {
      return this.editUserSettingsWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getUserSettingsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getUserSettings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'settings': this.apiClient.buildCollectionParam(opts['settings'], 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SettingList;
      return this.apiClient.callApi(
        '/settings/user/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getUserSettings(id, opts) {
      return this.getUserSettingsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

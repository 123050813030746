/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class TechniqueCreateAllOf {
    
    constructor(sku) { 
        
        TechniqueCreateAllOf.initialize(this, sku);
    }

    
    static initialize(obj, sku) { 
        obj['sku'] = sku;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueCreateAllOf();

            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
        }
        return obj;
    }


}


TechniqueCreateAllOf.prototype['sku'] = undefined;






export default TechniqueCreateAllOf;


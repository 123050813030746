/**
 * Files API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import FileItem from '../model/FileItem';


export default class FilesApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    uploadWithHttpInfo(directory, file1, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'directory' is set
      if (directory === undefined || directory === null) {
        throw new Error("Missing the required parameter 'directory' when calling upload");
      }
      // verify the required parameter 'file1' is set
      if (file1 === undefined || file1 === null) {
        throw new Error("Missing the required parameter 'file1' when calling upload");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'directory': directory,
        'file1': file1,
        'file2': opts['file2'],
        'file3': opts['file3'],
        'file4': opts['file4'],
        'file5': opts['file5'],
        'file6': opts['file6'],
        'file7': opts['file7'],
        'file8': opts['file8'],
        'file9': opts['file9'],
        'file10': opts['file10']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = [FileItem];
      return this.apiClient.callApi(
        '/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    upload(directory, file1, opts) {
      return this.uploadWithHttpInfo(directory, file1, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Device from './Device';


class CounterImport {
    
    constructor(devices) { 
        
        CounterImport.initialize(this, devices);
    }

    
    static initialize(obj, devices) { 
        obj['devices'] = devices;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CounterImport();

            if (data.hasOwnProperty('devices')) {
                obj['devices'] = ApiClient.convertToType(data['devices'], [Device]);
            }
            if (data.hasOwnProperty('errors')) {
                obj['errors'] = ApiClient.convertToType(data['errors'], ['String']);
            }
        }
        return obj;
    }


}


CounterImport.prototype['devices'] = undefined;


CounterImport.prototype['errors'] = undefined;






export default CounterImport;


/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';


class CounterHistory {
    
    constructor(codeName, value, operation, timestamp) { 
        
        CounterHistory.initialize(this, codeName, value, operation, timestamp);
    }

    
    static initialize(obj, codeName, value, operation, timestamp) { 
        obj['codeName'] = codeName;
        obj['value'] = value;
        obj['operation'] = operation;
        obj['timestamp'] = timestamp;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CounterHistory();

            if (data.hasOwnProperty('codeName')) {
                obj['codeName'] = ApiClient.convertToType(data['codeName'], 'String');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'Number');
            }
            if (data.hasOwnProperty('operation')) {
                obj['operation'] = ApiClient.convertToType(data['operation'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
        }
        return obj;
    }


}


CounterHistory.prototype['codeName'] = undefined;


CounterHistory.prototype['user'] = undefined;


CounterHistory.prototype['value'] = undefined;


CounterHistory.prototype['operation'] = undefined;


CounterHistory.prototype['timestamp'] = undefined;






CounterHistory['OperationEnum'] = {

    
    "add": "add",

    
    "reset": "reset"
};



export default CounterHistory;


const getCookie = name => {
  const cookiesArr = document.cookie.split(";");
  let nameCoockie = "";
  cookiesArr.forEach(elem => {
    if (elem.trim().slice(0, name.length) === name) {
      nameCoockie = elem.slice(name.length + 1);
    }
  });
  return nameCoockie.length > 0 ? nameCoockie : undefined;
};

const setCookie = (name, value, options = {}) => {
  options = {
    path: "/",
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export { getCookie, setCookie };

/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ChangeStatusHistoryAllOf from './ChangeStatusHistoryAllOf';
import History from './History';
import Status from './Status';


class ChangeStatusHistory {
    
    constructor(type, timestamp, status) { 
        History.initialize(this, type, timestamp);ChangeStatusHistoryAllOf.initialize(this, status);
        ChangeStatusHistory.initialize(this, type, timestamp, status);
    }

    
    static initialize(obj, type, timestamp, status) { 
        obj['status'] = status;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangeStatusHistory();
            History.constructFromObject(data, obj);
            History.constructFromObject(data, obj);
            ChangeStatusHistoryAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = Status.constructFromObject(data['status']);
            }
        }
        return obj;
    }


}


ChangeStatusHistory.prototype['description'] = undefined;


ChangeStatusHistory.prototype['status'] = undefined;


// Implement History interface:

History.prototype['type'] = undefined;

History.prototype['timestamp'] = undefined;
// Implement ChangeStatusHistoryAllOf interface:

ChangeStatusHistoryAllOf.prototype['description'] = undefined;

ChangeStatusHistoryAllOf.prototype['status'] = undefined;




export default ChangeStatusHistory;


import RequestManager from "../../../function/request/RequestManager";
import axios from "axios";
import eventBus from "@/utils/event-bus";
import { WEBSOCKET_URL } from "@/app-config";
import WebSocketService from "@/utils/web-socket";

let webSocketService;

export default {
  SET_AUTH: (ctx, value) => {
    ctx.commit("setAuth", value);
  },
  SET_ROLE: (ctx, role) => {
    ctx.commit("setRole", role);
  },
  SET_REFRESHED: ctx => {
    ctx.commit("setRefreshed");
  },
  TOGGLE_SHOW_INFO: ctx => {
    ctx.commit("changeInfoLabel");
  },
  async wakeAuth({ state, commit, dispatch }, login) {
    commit(
      "setAuth",
      !!window.localStorage.getItem("token") &&
        !!window.localStorage.getItem("refresh")
    );
    try {
      if (!state.isAuth) {
        const res = await RequestManager().login(login);
        if (!res.success && res.logError) {
          const err = res.logError.response.data.detail;
          throw err;
        }
        window.localStorage.setItem("token", res.access || "");
        window.localStorage.setItem("refresh", res.refresh || "");

        // при авторизации сбрасываем фильтры задач в дефолт
        window.localStorage.setItem("orders-list-params", "");
        window.localStorage.setItem("savedFilterParams", "");

        RequestManager()
          .loginApiV2(login)
          .then(res => {
            if (res.access) {
              localStorage.setItem("accessTokenV2", res.access);
              localStorage.setItem("refreshTokenV2", res.refresh);
              eventBus.$emit("token-v2-set");
              webSocketService = new WebSocketService(
                WEBSOCKET_URL,
                res.access
              );
              webSocketService.initListeners();
              dispatch("GET_CURRENT_USER_V2");
            }
          });
        commit("setAuth", true);
      }

      axios.defaults.headers = {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`
      };

      const user = await dispatch("GET_CURRENT_USER");
      // dispatch("GET_CURRENT_USER_V2");
      await dispatch("GET_USER_ROLES");
      return { err: user.err };
    } catch (err) {
      return { err: err };
    }
  },
  logout({ commit }) {
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("refresh", "");
    window.localStorage.setItem("accessTokenV2", "");
    window.localStorage.setItem("refreshTokenV2", "");
    window.localStorage.setItem("currentUser", "");
    window.localStorage.setItem("mainCompanyInfo", "");
    window.localStorage.setItem("orders-list-params", "");
    window.localStorage.setItem("isIntroPassed", "");
    window.localStorage.setItem("isGeneralInfoExist", "");
    window.localStorage.setItem("isAdminProfileFilled", "");
    window.localStorage.setItem("savedFilterParams", "");
    commit("setAuth", false);
    commit("setCurrentUser", {});
    if (webSocketService) {
      webSocketService.close();
    }
  }
};

export default {
  setAuth(state, value) {
    state.isAuth = value;
  },
  setRole(state, role) {
    state.role = role;
  },
  setRefreshed(state) {
    state.refreshed = true;
  },
  changeInfoLabel(state) {
    state.isShowInfoLabels = !state.isShowInfoLabels;
    localStorage.setItem("isShowHelpLabel", state.isShowInfoLabels.toString());
  }
};

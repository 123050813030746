/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class TechniqueReferencesEdit {
    
    constructor() { 
        
        TechniqueReferencesEdit.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueReferencesEdit();

            if (data.hasOwnProperty('parentIds')) {
                obj['parentIds'] = ApiClient.convertToType(data['parentIds'], ['Number']);
            }
            if (data.hasOwnProperty('childIds')) {
                obj['childIds'] = ApiClient.convertToType(data['childIds'], ['Number']);
            }
        }
        return obj;
    }


}


TechniqueReferencesEdit.prototype['parentIds'] = undefined;


TechniqueReferencesEdit.prototype['childIds'] = undefined;






export default TechniqueReferencesEdit;


/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class RecoveryPasswordEnterCode {
    
    constructor(email, code, password) { 
        
        RecoveryPasswordEnterCode.initialize(this, email, code, password);
    }

    
    static initialize(obj, email, code, password) { 
        obj['email'] = email;
        obj['code'] = code;
        obj['password'] = password;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RecoveryPasswordEnterCode();

            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
        }
        return obj;
    }


}


RecoveryPasswordEnterCode.prototype['email'] = undefined;


RecoveryPasswordEnterCode.prototype['code'] = undefined;


RecoveryPasswordEnterCode.prototype['password'] = undefined;






export default RecoveryPasswordEnterCode;


/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Device from './Device';
import Order from './Order';


class References {
    
    constructor(id) { 
        
        References.initialize(this, id);
    }

    
    static initialize(obj, id) { 
        obj['id'] = id;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new References();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('orders')) {
                obj['orders'] = ApiClient.convertToType(data['orders'], [Order]);
            }
            if (data.hasOwnProperty('ordersIds')) {
                obj['ordersIds'] = ApiClient.convertToType(data['ordersIds'], ['String']);
            }
            if (data.hasOwnProperty('reverseOrders')) {
                obj['reverseOrders'] = ApiClient.convertToType(data['reverseOrders'], [Order]);
            }
            if (data.hasOwnProperty('reverseOrdersIds')) {
                obj['reverseOrdersIds'] = ApiClient.convertToType(data['reverseOrdersIds'], ['String']);
            }
            if (data.hasOwnProperty('parentOrders')) {
                obj['parentOrders'] = ApiClient.convertToType(data['parentOrders'], [Order]);
            }
            if (data.hasOwnProperty('parentOrdersIds')) {
                obj['parentOrdersIds'] = ApiClient.convertToType(data['parentOrdersIds'], ['String']);
            }
            if (data.hasOwnProperty('childOrders')) {
                obj['childOrders'] = ApiClient.convertToType(data['childOrders'], [Order]);
            }
            if (data.hasOwnProperty('childOrdersIds')) {
                obj['childOrdersIds'] = ApiClient.convertToType(data['childOrdersIds'], ['String']);
            }
            if (data.hasOwnProperty('devices')) {
                obj['devices'] = ApiClient.convertToType(data['devices'], [Device]);
            }
            if (data.hasOwnProperty('devicesIds')) {
                obj['devicesIds'] = ApiClient.convertToType(data['devicesIds'], ['String']);
            }
        }
        return obj;
    }


}


References.prototype['id'] = undefined;


References.prototype['orders'] = undefined;


References.prototype['ordersIds'] = undefined;


References.prototype['reverseOrders'] = undefined;


References.prototype['reverseOrdersIds'] = undefined;


References.prototype['parentOrders'] = undefined;


References.prototype['parentOrdersIds'] = undefined;


References.prototype['childOrders'] = undefined;


References.prototype['childOrdersIds'] = undefined;


References.prototype['devices'] = undefined;


References.prototype['devicesIds'] = undefined;






export default References;


<template>
  <div class="main-block" :class="{ 'main-block_grey': modClass }">
    <Header v-if="contentClass !== undefined" :title="titlePage">
      <template #additionalTitle>
        <slot name="titleAppend" />
      </template>
    </Header>
    <div
      class="container container_without-menu page-content"
      :class="contentClass !== undefined ? contentClass : 'without-header'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Header from "./Header";
// import vuescroll from "vuescroll";
export default {
  name: "TepmplateBlock",
  components: { Header },
  props: {
    modClass: {
      type: Boolean
    },
    contentClass: {
      type: String
    },
    titlePage: String
  },
  mounted() {},
  data() {
    return {
      ops: {
        scrollPanel: {
          speed: 100
          // maxHeight: 640
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#006b90",
          opacity: 0.5,
          // specifyBorderRadius: false,
          minSize: 0.1,
          size: "6px"
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.wrapper-user-list {
  overflow: auto;
}

.main-wrap {
  align-items: flex-start;
  justify-content: flex-start;
}

.mytechnique-wrap {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.chat-wrap {
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100% - 140px);
}

.without-header {
  height: 100vh !important;
}

.d-block {
  display: block;
}
</style>

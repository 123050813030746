import SendRequest from "./SendRequest";
import ApiClient from "../../api/src/ApiClient";
import CategoryApi from "../../api/src/api/CategoryApi";
import TechniqueApi from "../../api/src/api/TechniqueApi";
import OrdersApi from "../../api/orders/src/api/OrderApi";
import FilesApi from "../../api/files/src/api/FilesApi";
import CompanyApi from "../../api/company/src/api/CompanyApi";
import OfficeApi from "../../api/office/src/api/OfficeApi";
import DeviceApi from "../../api/device/src/api/DeviceApi";
import CounterApi from "../../api/device/src/api/CounterApi";
import UserApi from "../../api/user/src/api/UserApi";
import ChatApi from "../../api/chat/src/api/ChatApi";
import ContactApi from "../../api/chat/src/api/ContactApi";
import QrApi from "../../api/device/src/api/QrApi";
import ProductApi from "../../api/shop/src/api/ProductApi";
import ShopOrderApi from "../../api/shop/src/api/OrderApi";
import NeoPharmOrderApi from "../../api/neopharm/src/api/OrderApi";

// added by sa
import SettingsUserApi from "../../api/settings/src/api/UserApi";

import NeoPharmOfficeApi from "../../api/neopharm/src/api/OfficeApi";
// import { instance } from "../axiosConfig";

export default function() {
  const baseApiUrl = process.env.VUE_APP_API_URL; // 'http://boxing.sparklingtide.com:8081/api'
  const token = localStorage.getItem("accessTokenV2") || "";
  const apiV2Client = new ApiClient(`${baseApiUrl}/v2/`);
  apiV2Client.defaultHeaders = { authorization: `Bearer ${token}` }; // TODO ADD REFRESH TOKEN
  // apiV2Client.requestAgent = instance;
  const categoryApi = new CategoryApi(apiV2Client);
  const techniqueApi = new TechniqueApi(apiV2Client);
  const ordersApi = new OrdersApi(apiV2Client);
  const filesApi = new FilesApi(apiV2Client);
  const companyApi = new CompanyApi(apiV2Client);
  const officeApi = new OfficeApi(apiV2Client);
  const deviceApi = new DeviceApi(apiV2Client);
  const counterApi = new CounterApi(apiV2Client);
  const userApi = new UserApi(apiV2Client);
  const chatApi = new ChatApi(apiV2Client);
  const contactApi = new ContactApi(apiV2Client);
  const qrApi = new QrApi(apiV2Client);
  const productApi = new ProductApi(apiV2Client);
  const shopOrderApi = new ShopOrderApi(apiV2Client);
  const neoPharmOrderApi = new NeoPharmOrderApi(apiV2Client);
  const neoPharmOfficeApi = new NeoPharmOfficeApi(apiV2Client);

  // added by sa
  const settingsUserApi = new SettingsUserApi(apiV2Client);

  //acl
  const getAcl = (appLabel, model, id) => {
    return SendRequest("GET", baseApiUrl + `/acl/${appLabel}/${model}/${id}`);
  };

  const putAcl = (appLabel, model, id, data) => {
    return SendRequest(
      "PUT",
      baseApiUrl + `/acl/${appLabel}/${model}/${id}`,
      data
    );
  };
  //   admin_profile

  // GET
  // admin_profile_list
  const getAdminProfile = () => {
    return SendRequest("GET", baseApiUrl + "/admin_profile/");
  };
  // PUT
  // admin_profile_update
  // PATCH
  // admin_profile_partial_update
  const updateAdminProfile = (id, data) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return SendRequest(
      "PUT",
      baseApiUrl + "/admin_profile/" + id + "/",
      data,
      headers
    );
  };

  const loginApiV2 = data => {
    return SendRequest("POST", baseApiUrl + "/v2/token", data);
  };

  const companyRegistrationV2 = data => {
    return SendRequest("POST", baseApiUrl + "/v2/company/registration", data);
  };

  const companyData = data => {
    return SendRequest("GET", baseApiUrl + "/v2/company/data", data);
  };

  // bank_info

  // GET
  // bank_info_list
  const getBankInfo = () => {
    return SendRequest("GET", baseApiUrl + "/bank_info/");
  };
  // PUT
  // bank_info_update
  const updateBankInfo = (id, data) => {
    return SendRequest("PUT", baseApiUrl + "/bank_info/" + id + "/", data);
  };
  // PATCH
  // bank_info_partial_update

  // calendar

  // GET
  // calendar_list
  // const getCalendar = (query = null) => {
  //   const date = new Date();
  //   const dateString = date.getFullYear() + "-" + (date.getMonth() + 1);
  //   const sendQuery = query !== null ? query : { month: dateString };
  //   return SendRequest("GET", baseApiUrl + "/calendar/", sendQuery);
  // };
  const getCalendar = () => {
    return SendRequest("GET", baseApiUrl + "/calendar/");
  };
  // POST
  // calendar_create

  // company_offices

  // GET
  // company_offices_list
  const getCompanyOfficesList = () => {
    return SendRequest("GET", baseApiUrl + "/company_offices/");
  };
  // POST
  // company_offices_create
  const addNewOffice = data => {
    return SendRequest("POST", baseApiUrl + "/company_offices/", data);
  };
  // PATCH
  const editOffice = (id, data) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return SendRequest(
      "PATCH",
      baseApiUrl + "/company_offices/" + id + "/",
      data,
      headers
    );
  };

  const getCompanyInfo = id => {
    const headers = {
      "Content-Type": "application/json"
    };
    return SendRequest("GET", `${baseApiUrl}/companyfull/${id}/`, headers);
  };

  // devices

  // GET
  // devices_list
  const getAllDevices = (query = null, offset = 0) => {
    const sentQuery =
      query === null
        ? { limit: 5000, offset }
        : { limit: 6000, offset, ...query };
    return SendRequest("GET", baseApiUrl + "/devices/", sentQuery);
  };
  // GET
  // devices_types
  const getDevicesTypes = () => {
    return SendRequest("GET", baseApiUrl + "/devices/get_types/");
  };
  // GET
  // devices_status
  const getDevicesStatus = () => {
    return SendRequest("GET", baseApiUrl + "/devices/get_status/");
  };

  //PUT

  const editDevice = (id, data) => {
    return SendRequest("PUT", baseApiUrl + "/devices/" + id + "/", data);
  };

  //GET
  const getQrListForDevicesByOfficeId = data => {
    const _data = {
      limit: data.limit ?? 100,
      offset: data.offset ?? 0,
      office_id: data.office_id,
      start_position: data.start_position ?? 0,
      device_id: data.device_id ?? undefined
    };
    return SendRequest(
      "GET",
      baseApiUrl + "/devices/qr_list_pdf/",
      _data,
      {
        "Content-Type": "application/pdf"
      },
      "blob"
    );
  };

  // POST
  // devices_create
  const addNewDevice = data => {
    const headers = {
      "Content-Type": "multipart/form-data"
      // "Content-Disposition": 'form-data; name="attributes"',
      // "Content-Type": "application/json"
    };
    return SendRequest("POST", baseApiUrl + "/devices/", data, headers);
  };
  // GET
  // devices_read
  const getSingleDevice = id => {
    return SendRequest("GET", baseApiUrl + "/devices/" + id + "/");
  };

  // GET
  // device ticket
  const getDeviceTicket = id => {
    return SendRequest("GET", baseApiUrl + "/devices/" + id + "/qr/");
  };
  // PUT
  // devices_update

  // PATCH
  // devices_partial_update
  const updateSingleDevice = (id, data) => {
    return SendRequest("PATCH", baseApiUrl + "/devices/" + id + "/", data);
  };
  // main_company_info

  // GET
  // main_company_info_list
  const getMainCompanyInfo = () => {
    return SendRequest("GET", baseApiUrl + "/main_company_info/");
  };
  // PUT
  // main_company_info_update
  const updateCompanyInfo = (id, data) => {
    return SendRequest(
      "PUT",
      baseApiUrl + "/main_company_info/" + id + "/",
      data
    );
  };
  // PATCH
  // main_company_info_partial_update

  // GET
  // main_company_info_chat
  const getMainCompanyChat = (id, offset = 0) => {
    const query = { limit: 20, offset };
    return SendRequest(
      "GET",
      baseApiUrl + "/main_company_info/" + id + "/chat/",
      query
    );
  };
  // POST
  // main_company_info_send_message
  const sendMainCompanyChatMessage = (id, data) => {
    return SendRequest(
      "POST",
      baseApiUrl + "/main_company_info/" + id + "/send_message/",
      data
    );
  };

  //  /api/notifications/
  const getNotifications = () => {
    return SendRequest("GET", baseApiUrl + "/notifications/");
  };

  // orders

  // GET
  // orders_list
  const getOrdersList = (offset = 0) => {
    const query = { limit: offset === 0 ? 20 : 21, offset };
    return SendRequest("GET", baseApiUrl + "/orders/", query);
  };

  const getOrdersListWithParams = data => {
    let query = {
      limit: data.limit ? data.limit : 21,
      offset: data.offset ? data.offset : 0,
      device_tag_id: data.device_tag_id ? data.device_tag_id : undefined,
      device_id: data.device_id ? data.device_id : undefined,
      device_serial_num: data.device_serial_num
        ? data.device_serial_num
        : undefined,
      device_sticker_num: data.device_sticker_num
        ? data.device_sticker_num
        : undefined,
      created_at_min: data.created_at_min ? data.created_at_min : undefined,
      created_at_max: data.created_at_max ? data.created_at_max : undefined,
      updated_at_min: data.updated_at_min ? data.updated_at_min : undefined,
      updated_at_max: data.updated_at_max ? data.updated_at_max : undefined,
      status_id: data.status_id ? data.status_id : undefined,
      status_ids: data.status_ids ? data.status_ids : undefined,
      status_not_ids: data.status_not_ids ? data.status_not_ids : undefined,
      office_id: data.office_id ? data.office_id : undefined,
      author_ids: data.author_ids ? data.author_ids : undefined
    };
    return SendRequest("GET", baseApiUrl + "/orders/", query);
  };
  // POST
  // orders_create
  const createNewOrder = data => {
    return SendRequest("POST", baseApiUrl + "/orders/", data);
  };
  // GET
  // orders_get_order_type

  const getOrdersType = () => {
    return SendRequest("GET", baseApiUrl + "/orders/get_order_type/");
  };
  // GET
  // orders_read
  const getOrderById = id => {
    return SendRequest("GET", baseApiUrl + "/orders/" + id + "/");
  };

  // PUT
  // orders_update

  // PATCH
  // orders_partial_update

  // DELETE
  // orders_delete

  // GET
  // orders_chat
  const getOrdersChat = (id, offset = 0) => {
    const sentQuery = { limit: 20, offset };
    return SendRequest(
      "GET",
      baseApiUrl + "/orders/" + id + "/chat/",
      sentQuery
    );
  };

  // GET
  // orders_history
  const getOrdersChatHistory = id => {
    return SendRequest("GET", baseApiUrl + "/orders/" + id + "/history");
  };
  const getOrdersStatus = id => {
    return SendRequest(
      "GET",
      baseApiUrl + "/orders/" + id + "/history/orders_history/"
    );
  };
  // POST
  // orders_send_message
  const sendOrdersChatMessage = (id, data, headers = null) => {
    return SendRequest(
      "POST",
      baseApiUrl + "/orders/" + id + "/send_message/",
      data,
      headers
    );
  };
  // register

  // POST
  // register_request_reset
  const requestReset = data => {
    return SendRequest("POST", baseApiUrl + "/register/request_reset/", data);
  };
  // POST

  // register_reset_password
  const resetPassword = data => {
    return SendRequest("POST", baseApiUrl + "/register/reset_password/", data);
  };
  // POST
  // register_signup
  const register = data => {
    return SendRequest("POST", baseApiUrl + "/register/signup/", data);
  };

  // tags

  // GET
  // tags_list
  const getTagsList = () => {
    return SendRequest("GET", baseApiUrl + "/tags/");
  };
  // POST
  // tags_create

  const createTag = data => {
    return SendRequest("POST", baseApiUrl + "/tags/", data);
  };
  // token
  // POST
  // token_create
  const login = data => {
    return SendRequest("POST", baseApiUrl + "/token/", data);
  };
  // POST
  // token_refresh_create

  // users

  const getOrdersStatuses = () => {
    return SendRequest("GET", baseApiUrl + "/order_statuses/");
  };

  const addOrderStep = (id, data) => {
    return SendRequest(
      "POST",
      baseApiUrl + "/orders/" + id + "/add_step/",
      data
    );
  };

  // GET
  // users_list
  const getUsersList = () => {
    const data = { limit: 10000, offset: 0 };
    return SendRequest("GET", baseApiUrl + "/users/", data);
  };

  const getUsersListAll = () => {
    const data = { offset: 0 };
    return SendRequest("GET", baseApiUrl + "/users/", data);
  };

  // POST
  // users_create
  const createNewUser = data => {
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    return SendRequest("POST", baseApiUrl + "/users/", data, headers);
  };
  // GET
  // get current user
  const getCurrentUser = () => {
    return SendRequest("GET", baseApiUrl + "/users/get_current_user/");
  };

  // GET
  // users_get_roles
  const getUserRoles = () => {
    return SendRequest("GET", baseApiUrl + "/users/get_roles/");
  };
  // GET
  // users_read
  const getSingleUser = id => {
    return SendRequest("GET", baseApiUrl + "/users/" + id + "/");
  };
  // PUT
  // users_update
  const updateUser = (id, data) => {
    return SendRequest("PATCH", baseApiUrl + "/users/" + id + "/", data);
  };
  // PATCH
  // users_partial_update
  const updateUserPhoto = (id, data) => {
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    return SendRequest(
      "PATCH",
      baseApiUrl + "/users/" + id + "/",
      data,
      headers
    );
  };
  // DELETE
  // users_delete
  const deleteUser = id => {
    return SendRequest("DELETE", baseApiUrl + "/users/" + id + "/");
  };

  // POST
  // change user password
  const changeUserPassword = (userId, oldPassword, newPassword) => {
    const query = {
      old: oldPassword,
      new: newPassword
    };
    return SendRequest(
      "POST",
      baseApiUrl + "/users/" + userId + "/change_password/",
      query
    );
  };

  // CHATS
  // chat list
  const chatList = (limit = 1000, offset = 0) => {
    const query = { limit: limit, offset: offset };
    return SendRequest("GET", baseApiUrl + "/chat/", query);
  };
  // group chat create
  const chatCreate = (title = "Новый чат") => {
    return SendRequest("POST", baseApiUrl + "/chat/", { title: title });
  };
  // personal chat create
  const personalChatCreate = userId => {
    return SendRequest("POST", baseApiUrl + "/chat/by_user/", {
      user_id: userId
    });
  };
  // get chat by chat id
  const getChatById = id => {
    return SendRequest("GET", baseApiUrl + "/chat/" + id);
  };
  // change chat's name
  const updateChatName = (id, title) => {
    return SendRequest("PUT", baseApiUrl + "/chat/" + id, { title: title });
  };
  // get chat messages
  const getMessages = (id, limit = 20, offset = 0) => {
    const query = { limit: limit, offset: offset };
    return SendRequest("GET", baseApiUrl + "/chat/" + id + "/messages/", query);
  };
  // send message
  const sendMessage = (id, message) => {
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    return SendRequest(
      "POST",
      baseApiUrl + "/chat/" + id + "/messages/",
      message,
      headers
    );
  };
  const deleteChat = id => {
    return SendRequest("DELETE", `${baseApiUrl}/chat/${id}/`);
  };

  return {
    //chats
    chatList,
    chatCreate,
    personalChatCreate,
    getChatById,
    updateChatName,
    getMessages,
    sendMessage,
    deleteChat,

    //acl
    getAcl,
    putAcl,
    // admin-profile
    getAdminProfile,
    updateAdminProfile,
    // bank-info
    getBankInfo,
    updateBankInfo,
    // calendar
    getCalendar,
    // company_offices
    getCompanyOfficesList,
    addNewOffice,
    editOffice,
    getCompanyInfo,
    // devices
    getQrListForDevicesByOfficeId,
    getAllDevices,
    getDevicesTypes,
    getDevicesStatus,
    addNewDevice,
    getSingleDevice,
    getDeviceTicket,
    updateSingleDevice,
    editDevice,

    // main company info
    getMainCompanyInfo,
    updateCompanyInfo,
    getMainCompanyChat,
    sendMainCompanyChatMessage,
    // notifications
    getNotifications,
    // orders
    getOrdersStatuses,
    addOrderStep,
    getOrdersList,
    getOrdersListWithParams,
    getOrderById,
    createNewOrder,
    getOrdersType,
    getOrdersChat,
    getOrdersChatHistory,
    getOrdersStatus,
    sendOrdersChatMessage,
    // register
    requestReset,
    resetPassword,
    register,
    // tags
    getTagsList,
    createTag,
    // token
    login,
    //users
    getUsersList,
    getUsersListAll,
    createNewUser,
    getCurrentUser,
    getUserRoles,
    getSingleUser,
    updateUser,
    updateUserPhoto,
    deleteUser,
    changeUserPassword,
    loginApiV2,
    companyRegistrationV2,
    companyData,
    categoryApi,
    techniqueApi,
    ordersApi,
    filesApi,
    companyApi,
    officeApi,
    deviceApi,
    counterApi,
    userApi,
    chatApi,
    contactApi,
    qrApi,
    productApi,
    shopOrderApi,
    neoPharmOrderApi,
    neoPharmOfficeApi,

    // added by sa
    settingsUserApi
  };
}

/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Office from '../model/Office';


export default class OfficeApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    deleteOfficeWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteOffice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/neo-pharm/office/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteOffice(id) {
      return this.deleteOfficeWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editOfficeWithHttpInfo(id, externalId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editOffice");
      }
      // verify the required parameter 'externalId' is set
      if (externalId === undefined || externalId === null) {
        throw new Error("Missing the required parameter 'externalId' when calling editOffice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'externalId': externalId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Office;
      return this.apiClient.callApi(
        '/neo-pharm/office/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editOffice(id, externalId) {
      return this.editOfficeWithHttpInfo(id, externalId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOfficeWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOffice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Office;
      return this.apiClient.callApi(
        '/neo-pharm/office/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOffice(id) {
      return this.getOfficeWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOfficeByDeviceWithHttpInfo(deviceId) {
      let postBody = null;
      // verify the required parameter 'deviceId' is set
      if (deviceId === undefined || deviceId === null) {
        throw new Error("Missing the required parameter 'deviceId' when calling getOfficeByDevice");
      }

      let pathParams = {
      };
      let queryParams = {
        'deviceId': deviceId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Office;
      return this.apiClient.callApi(
        '/neo-pharm/offices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOfficeByDevice(deviceId) {
      return this.getOfficeByDeviceWithHttpInfo(deviceId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Message from './Message';


class MessageList {
    
    constructor(items, count) { 
        
        MessageList.initialize(this, items, count);
    }

    
    static initialize(obj, items, count) { 
        obj['items'] = items;
        obj['count'] = count;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MessageList();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Message]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }


}


MessageList.prototype['items'] = undefined;


MessageList.prototype['count'] = undefined;






export default MessageList;


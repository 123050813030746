/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CategorySort {
    
    constructor(categoryId, sort) { 
        
        CategorySort.initialize(this, categoryId, sort);
    }

    
    static initialize(obj, categoryId, sort) { 
        obj['categoryId'] = categoryId;
        obj['sort'] = sort;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CategorySort();

            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('sort')) {
                obj['sort'] = ApiClient.convertToType(data['sort'], 'Number');
            }
        }
        return obj;
    }


}


CategorySort.prototype['categoryId'] = undefined;


CategorySort.prototype['sort'] = undefined;






export default CategorySort;


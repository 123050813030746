/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import HistoryList from '../model/HistoryList';
import Order from '../model/Order';
import OrderCreate from '../model/OrderCreate';
import OrderCreateQrCode from '../model/OrderCreateQrCode';
import OrderEdit from '../model/OrderEdit';
import OrdersList from '../model/OrdersList';
import Permission from '../model/Permission';
import PermissionEdit from '../model/PermissionEdit';
import References from '../model/References';
import ReferencesEdit from '../model/ReferencesEdit';
import Status from '../model/Status';
import Type from '../model/Type';


export default class OrderApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createOrderWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createOrder");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;
      return this.apiClient.callApi(
        '/orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrder(body) {
      return this.createOrderWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrderByQrCodeWithHttpInfo(device, body) {
      let postBody = body;
      // verify the required parameter 'device' is set
      if (device === undefined || device === null) {
        throw new Error("Missing the required parameter 'device' when calling createOrderByQrCode");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createOrderByQrCode");
      }

      let pathParams = {
      };
      let queryParams = {
        'device': device
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/orders/qr-code', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrderByQrCode(device, body) {
      return this.createOrderByQrCodeWithHttpInfo(device, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteOrderWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/order/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteOrder(id) {
      return this.deleteOrderWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editOrderWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editOrder");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling editOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;
      return this.apiClient.callApi(
        '/order/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editOrder(id, body) {
      return this.editOrderWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editOrderPermissionsWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editOrderPermissions");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling editOrderPermissions");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Permission];
      return this.apiClient.callApi(
        '/order/{id}/permissions', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editOrderPermissions(id, body) {
      return this.editOrderPermissionsWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editOrderReferencesWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editOrderReferences");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling editOrderReferences");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = References;
      return this.apiClient.callApi(
        '/order/{id}/references', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editOrderReferences(id, body) {
      return this.editOrderReferencesWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;
      return this.apiClient.callApi(
        '/order/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrder(id) {
      return this.getOrderWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderHistoryWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrderHistory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'type': opts['type'],
        'offset': opts['offset'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = HistoryList;
      return this.apiClient.callApi(
        '/order/{id}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrderHistory(id, opts) {
      return this.getOrderHistoryWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderPermissionsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrderPermissions");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Permission];
      return this.apiClient.callApi(
        '/order/{id}/permissions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrderPermissions(id) {
      return this.getOrderPermissionsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderReferencesWithHttpInfo(id, references) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrderReferences");
      }
      // verify the required parameter 'references' is set
      if (references === undefined || references === null) {
        throw new Error("Missing the required parameter 'references' when calling getOrderReferences");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'references': this.apiClient.buildCollectionParam(references, 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = References;
      return this.apiClient.callApi(
        '/order/{id}/references', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrderReferences(id, references) {
      return this.getOrderReferencesWithHttpInfo(id, references)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderStatusesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Status];
      return this.apiClient.callApi(
        '/orders/statuses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrderStatuses() {
      return this.getOrderStatusesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderTypesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Type];
      return this.apiClient.callApi(
        '/orders/types', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrderTypes() {
      return this.getOrderTypesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrdersReferencesWithHttpInfo(ids, references) {
      let postBody = null;
      // verify the required parameter 'ids' is set
      if (ids === undefined || ids === null) {
        throw new Error("Missing the required parameter 'ids' when calling getOrdersReferences");
      }
      // verify the required parameter 'references' is set
      if (references === undefined || references === null) {
        throw new Error("Missing the required parameter 'references' when calling getOrdersReferences");
      }

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(ids, 'csv'),
        'references': this.apiClient.buildCollectionParam(references, 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [References];
      return this.apiClient.callApi(
        '/orders/references', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrdersReferences(ids, references) {
      return this.getOrdersReferencesWithHttpInfo(ids, references)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    listOrdersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'companyId': opts['companyId'],
        'visibility': opts['visibility'],
        'currentUserRole': this.apiClient.buildCollectionParam(opts['currentUserRole'], 'csv'),
        'authorId': opts['authorId'],
        'performerId': opts['performerId'],
        'viewerId': opts['viewerId'],
        'typeIds': this.apiClient.buildCollectionParam(opts['typeIds'], 'csv'),
        'priority': this.apiClient.buildCollectionParam(opts['priority'], 'csv'),
        'statusIds': this.apiClient.buildCollectionParam(opts['statusIds'], 'csv'),
        'estimationMin': opts['estimationMin'],
        'estimationMax': opts['estimationMax'],
        'tagIds': this.apiClient.buildCollectionParam(opts['tagIds'], 'csv'),
        'ordersIds': this.apiClient.buildCollectionParam(opts['ordersIds'], 'csv'),
        'reverseOrdersIds': this.apiClient.buildCollectionParam(opts['reverseOrdersIds'], 'csv'),
        'parentOrdersIds': this.apiClient.buildCollectionParam(opts['parentOrdersIds'], 'csv'),
        'childOrdersIds': this.apiClient.buildCollectionParam(opts['childOrdersIds'], 'csv'),
        'devicesIds': this.apiClient.buildCollectionParam(opts['devicesIds'], 'csv'),
        'deviceName': opts['deviceName'],
        'deviceOfficeId': opts['deviceOfficeId'],
        'createdAtMin': opts['createdAtMin'],
        'createdAtMax': opts['createdAtMax'],
        'updatedAtMin': opts['updatedAtMin'],
        'updatedAtMax': opts['updatedAtMax'],
        'offset': opts['offset'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'order': opts['order'],
        'childLevels': opts['childLevels'],
        'filterRootLevel': opts['filterRootLevel']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrdersList;
      return this.apiClient.callApi(
        '/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    listOrders(opts) {
      return this.listOrdersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * Company API
 * Used for: - Manage Company 
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Company from '../model/Company';
import CompanyCreate from '../model/CompanyCreate';
import CompanyEdit from '../model/CompanyEdit';
import CompanyList from '../model/CompanyList';
import CompanyRegistration from '../model/CompanyRegistration';
import DaData from '../model/DaData';


export default class CompanyApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createCompanyWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/company', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createCompany(opts) {
      return this.createCompanyWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteCompanyWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/company/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteCompany(id) {
      return this.deleteCompanyWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editCompanyWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/company/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editCompany(id, opts) {
      return this.editCompanyWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getCompanyWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/company/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getCompany(id) {
      return this.getCompanyWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getDataWithHttpInfo(inn) {
      let postBody = null;
      // verify the required parameter 'inn' is set
      if (inn === undefined || inn === null) {
        throw new Error("Missing the required parameter 'inn' when calling getData");
      }

      let pathParams = {
      };
      let queryParams = {
        'inn': inn
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DaData;
      return this.apiClient.callApi(
        '/company/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getData(inn) {
      return this.getDataWithHttpInfo(inn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    listCompaniesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'csv'),
        'email': opts['email'],
        'inn': opts['inn'],
        'brand': opts['brand'],
        'legalEntity': opts['legalEntity'],
        'crmManagerId': opts['crmManagerId'],
        'enabled': opts['enabled'],
        'offset': opts['offset'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'order': opts['order']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyList;
      return this.apiClient.callApi(
        '/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    listCompanies(opts) {
      return this.listCompaniesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    registrationCompanyWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling registrationCompany");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/company/registration', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    registrationCompany(body) {
      return this.registrationCompanyWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    softDeleteCompanyWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling softDeleteCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/company/{id}/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    softDeleteCompany(id) {
      return this.softDeleteCompanyWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

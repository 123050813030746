/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FileItem from './FileItem';
import TechniqueAuthor from './TechniqueAuthor';


class TechniqueAllOf1 {
    
    constructor(files) { 
        
        TechniqueAllOf1.initialize(this, files);
    }

    
    static initialize(obj, files) { 
        obj['files'] = files;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueAllOf1();

            if (data.hasOwnProperty('author')) {
                obj['author'] = TechniqueAuthor.constructFromObject(data['author']);
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [FileItem]);
            }
        }
        return obj;
    }


}


TechniqueAllOf1.prototype['author'] = undefined;


TechniqueAllOf1.prototype['files'] = undefined;






export default TechniqueAllOf1;


/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';


class Permission {
    
    constructor(user, roles) { 
        
        Permission.initialize(this, user, roles);
    }

    
    static initialize(obj, user, roles) { 
        obj['user'] = user;
        obj['roles'] = roles;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Permission();

            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], ['String']);
            }
        }
        return obj;
    }


}


Permission.prototype['user'] = undefined;


Permission.prototype['roles'] = undefined;






Permission['RolesEnum'] = {

    
    "admin": "admin",

    
    "canRead": "canRead",

    
    "canWrite": "canWrite"
};



export default Permission;


/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Attachment {
    
    constructor(title, objectType) { 
        
        Attachment.initialize(this, title, objectType);
    }

    
    static initialize(obj, title, objectType) { 
        obj['title'] = title;
        obj['objectType'] = objectType;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Attachment();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('messageId')) {
                obj['messageId'] = ApiClient.convertToType(data['messageId'], 'String');
            }
            if (data.hasOwnProperty('objectType')) {
                obj['objectType'] = ApiClient.convertToType(data['objectType'], 'String');
            }
        }
        return obj;
    }


}


Attachment.prototype['title'] = undefined;


Attachment.prototype['url'] = undefined;


Attachment.prototype['timestamp'] = undefined;


Attachment.prototype['messageId'] = undefined;


Attachment.prototype['objectType'] = undefined;






Attachment['ObjectTypeEnum'] = {

    
    "FileAttachment": "FileAttachment",

    
    "ImageAttachment": "ImageAttachment",

    
    "VideoAttachment": "VideoAttachment",

    
    "UrlAttachment": "UrlAttachment"
};



export default Attachment;


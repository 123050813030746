/**
 * Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Value {
    
    constructor(type) { 
        
        Value.initialize(this, type);
    }

    
    static initialize(obj, type) { 
        obj['type'] = type;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Value();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
        }
        return obj;
    }


}


Value.prototype['type'] = undefined;






Value['TypeEnum'] = {

    
    "StringValue": "StringValue",

    
    "IntValue": "IntValue",

    
    "BoolValue": "BoolValue",

    
    "ObjectValue": "ObjectValue"
};



export default Value;


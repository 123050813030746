/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class InlineObject2 {
    
    constructor(oldPassword, newPassword) { 
        
        InlineObject2.initialize(this, oldPassword, newPassword);
    }

    
    static initialize(obj, oldPassword, newPassword) { 
        obj['oldPassword'] = oldPassword;
        obj['newPassword'] = newPassword;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject2();

            if (data.hasOwnProperty('oldPassword')) {
                obj['oldPassword'] = ApiClient.convertToType(data['oldPassword'], 'String');
            }
            if (data.hasOwnProperty('newPassword')) {
                obj['newPassword'] = ApiClient.convertToType(data['newPassword'], 'String');
            }
        }
        return obj;
    }


}


InlineObject2.prototype['oldPassword'] = undefined;


InlineObject2.prototype['newPassword'] = undefined;






export default InlineObject2;


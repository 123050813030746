/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BaseTechnique from './BaseTechnique';
import FileItem from './FileItem';
import Parameter from './Parameter';
import TechniqueAllOf from './TechniqueAllOf';
import TechniqueAllOf1 from './TechniqueAllOf1';
import TechniqueAuthor from './TechniqueAuthor';


class Technique {
    
    constructor(id, sku, title, description, parameters, categoryId, files) { 
        TechniqueAllOf.initialize(this, id, sku);BaseTechnique.initialize(this, title, description, parameters, categoryId);TechniqueAllOf1.initialize(this, files);
        Technique.initialize(this, id, sku, title, description, parameters, categoryId, files);
    }

    
    static initialize(obj, id, sku, title, description, parameters, categoryId, files) { 
        obj['id'] = id;
        obj['sku'] = sku;
        obj['title'] = title;
        obj['description'] = description;
        obj['parameters'] = parameters;
        obj['categoryId'] = categoryId;
        obj['files'] = files;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Technique();
            TechniqueAllOf.constructFromObject(data, obj);
            BaseTechnique.constructFromObject(data, obj);
            TechniqueAllOf1.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [Parameter]);
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('author')) {
                obj['author'] = TechniqueAuthor.constructFromObject(data['author']);
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [FileItem]);
            }
        }
        return obj;
    }


}


Technique.prototype['id'] = undefined;


Technique.prototype['sku'] = undefined;


Technique.prototype['title'] = undefined;


Technique.prototype['description'] = undefined;


Technique.prototype['parameters'] = undefined;


Technique.prototype['categoryId'] = undefined;


Technique.prototype['author'] = undefined;


Technique.prototype['files'] = undefined;


// Implement TechniqueAllOf interface:

TechniqueAllOf.prototype['id'] = undefined;

TechniqueAllOf.prototype['sku'] = undefined;
// Implement BaseTechnique interface:

BaseTechnique.prototype['title'] = undefined;

BaseTechnique.prototype['description'] = undefined;

BaseTechnique.prototype['parameters'] = undefined;

BaseTechnique.prototype['categoryId'] = undefined;
// Implement TechniqueAllOf1 interface:

TechniqueAllOf1.prototype['author'] = undefined;

TechniqueAllOf1.prototype['files'] = undefined;




export default Technique;


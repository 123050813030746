import AutoReload from "./AutoReload";

const AutoReloadModule = {
  install(Vue, options) {
    const module = new AutoReload(options);
    module.init();

    Vue.prototype.$autoReload = module;
  }
};

export default AutoReloadModule;

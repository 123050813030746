/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Technique from '../model/Technique';
import TechniqueCreate from '../model/TechniqueCreate';
import TechniqueEdit from '../model/TechniqueEdit';
import TechniqueList from '../model/TechniqueList';
import TechniqueReferences from '../model/TechniqueReferences';
import TechniqueReferencesEdit from '../model/TechniqueReferencesEdit';


export default class TechniqueApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    getTechniqueReferencesWithHttpInfo(id, fields) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getTechniqueReferences");
      }
      // verify the required parameter 'fields' is set
      if (fields === undefined || fields === null) {
        throw new Error("Missing the required parameter 'fields' when calling getTechniqueReferences");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'fields': this.apiClient.buildCollectionParam(fields, 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TechniqueReferences;
      return this.apiClient.callApi(
        '/technique/{id}/references', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getTechniqueReferences(id, fields) {
      return this.getTechniqueReferencesWithHttpInfo(id, fields)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    setTechniqueReferencesWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setTechniqueReferences");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling setTechniqueReferences");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TechniqueReferences;
      return this.apiClient.callApi(
        '/technique/{id}/references', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    setTechniqueReferences(id, body) {
      return this.setTechniqueReferencesWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    techniqueCreateWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling techniqueCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Technique;
      return this.apiClient.callApi(
        '/techniques', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    techniqueCreate(body) {
      return this.techniqueCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    techniqueDeleteWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling techniqueDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/technique/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    techniqueDelete(id) {
      return this.techniqueDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    techniqueEditWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling techniqueEdit");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling techniqueEdit");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Technique;
      return this.apiClient.callApi(
        '/technique/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    techniqueEdit(id, body) {
      return this.techniqueEditWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    techniqueGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling techniqueGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Technique;
      return this.apiClient.callApi(
        '/technique/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    techniqueGet(id) {
      return this.techniqueGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    techniqueListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'csv'),
        'skus': this.apiClient.buildCollectionParam(opts['skus'], 'csv'),
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'csv'),
        'title': opts['title'],
        'parameters': opts['parameters'],
        'parentIds': this.apiClient.buildCollectionParam(opts['parentIds'], 'csv'),
        'childIds': this.apiClient.buildCollectionParam(opts['childIds'], 'csv'),
        'ancestorIds': this.apiClient.buildCollectionParam(opts['ancestorIds'], 'csv'),
        'descendantIds': this.apiClient.buildCollectionParam(opts['descendantIds'], 'csv'),
        'offset': opts['offset'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'order': opts['order']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TechniqueList;
      return this.apiClient.callApi(
        '/techniques', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    techniqueList(opts) {
      return this.techniqueListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

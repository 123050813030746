// Rewrite History.constructFromObject static method
import History from "@/api/orders/src/model/History";
import ReferenceHistory from "@/api/orders/src/model/ReferenceHistory";
import ChangeStatusHistory from "@/api/orders/src/model/ChangeStatusHistory";
import ChangePermissionsHistory from "@/api/orders/src/model/ChangePermissionsHistory";

export const overwriteOrderHistory = () => {
  let _History_constructFromObject = History.constructFromObject;

  History.constructFromObject = function(data, obj) {
    let history;
    if (data["type"] === "ReferenceHistory") {
      delete data["type"]; // Fix recursive call
      history = ReferenceHistory.constructFromObject(data, obj);
      history.type = "ReferenceHistory";
    } else if (data["type"] === "ChangeStatusHistory") {
      delete data["type"]; // Fix recursive call
      history = ChangeStatusHistory.constructFromObject(data, obj);
      history.type = "ChangeStatusHistory";
    } else if (data["type"] === "ChangePermissionsHistory") {
      delete data["type"]; // Fix recursive call
      history = ChangePermissionsHistory.constructFromObject(data, obj);
      history.type = "ChangePermissionsHistory";
    } else {
      // Call orig method
      history = _History_constructFromObject(data, obj);
    }

    return history;
  };
};

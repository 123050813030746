/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Permission from './Permission';


class Chat {
    
    constructor(title, permissions) { 
        
        Chat.initialize(this, title, permissions);
    }

    
    static initialize(obj, title, permissions) { 
        obj['title'] = title;
        obj['permissions'] = permissions;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Chat();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('newMessagesCount')) {
                obj['newMessagesCount'] = ApiClient.convertToType(data['newMessagesCount'], 'Number');
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = ApiClient.convertToType(data['permissions'], [Permission]);
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = ApiClient.convertToType(data['metadata'], Object);
            }
        }
        return obj;
    }


}


Chat.prototype['id'] = undefined;


Chat.prototype['title'] = undefined;


Chat.prototype['newMessagesCount'] = undefined;


Chat.prototype['permissions'] = undefined;


Chat.prototype['metadata'] = undefined;






export default Chat;


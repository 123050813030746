/**
 * Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Value from './Value';


class Setting {
    
    constructor(name, value) { 
        
        Setting.initialize(this, name, value);
    }

    
    static initialize(obj, name, value) { 
        obj['name'] = name;
        obj['value'] = value;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Setting();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = Value.constructFromObject(data['value']);
            }
        }
        return obj;
    }


}


Setting.prototype['name'] = undefined;


Setting.prototype['value'] = undefined;






export default Setting;


/**
 * Office API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Office {
    
    constructor(id, companyId, name, city, street, homeNum, phone) { 
        
        Office.initialize(this, id, companyId, name, city, street, homeNum, phone);
    }

    
    static initialize(obj, id, companyId, name, city, street, homeNum, phone) { 
        obj['id'] = id;
        obj['companyId'] = companyId;
        obj['name'] = name;
        obj['city'] = city;
        obj['street'] = street;
        obj['homeNum'] = homeNum;
        obj['phone'] = phone;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Office();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Number');
            }
            if (data.hasOwnProperty('companyBrand')) {
                obj['companyBrand'] = ApiClient.convertToType(data['companyBrand'], 'String');
            }
            if (data.hasOwnProperty('companyLegalEntity')) {
                obj['companyLegalEntity'] = ApiClient.convertToType(data['companyLegalEntity'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('index')) {
                obj['index'] = ApiClient.convertToType(data['index'], 'String');
            }
            if (data.hasOwnProperty('street')) {
                obj['street'] = ApiClient.convertToType(data['street'], 'String');
            }
            if (data.hasOwnProperty('homeNum')) {
                obj['homeNum'] = ApiClient.convertToType(data['homeNum'], 'String');
            }
            if (data.hasOwnProperty('structNum')) {
                obj['structNum'] = ApiClient.convertToType(data['structNum'], 'String');
            }
            if (data.hasOwnProperty('enterNum')) {
                obj['enterNum'] = ApiClient.convertToType(data['enterNum'], 'String');
            }
            if (data.hasOwnProperty('floor')) {
                obj['floor'] = ApiClient.convertToType(data['floor'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
        }
        return obj;
    }


}


Office.prototype['id'] = undefined;


Office.prototype['companyId'] = undefined;


Office.prototype['companyBrand'] = undefined;


Office.prototype['companyLegalEntity'] = undefined;


Office.prototype['name'] = undefined;


Office.prototype['city'] = undefined;


Office.prototype['index'] = undefined;


Office.prototype['street'] = undefined;


Office.prototype['homeNum'] = undefined;


Office.prototype['structNum'] = undefined;


Office.prototype['enterNum'] = undefined;


Office.prototype['floor'] = undefined;


Office.prototype['phone'] = undefined;






export default Office;


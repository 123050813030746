/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class InlineResponse200 {
    
    constructor() { 
        
        InlineResponse200.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineResponse200();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], Object);
            }
        }
        return obj;
    }


}


InlineResponse200.prototype['status'] = undefined;






export default InlineResponse200;


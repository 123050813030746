/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Attachment from './Attachment';
import User from './User';


class Message {
    
    constructor() { 
        
        Message.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Message();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('chatId')) {
                obj['chatId'] = ApiClient.convertToType(data['chatId'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('attachments')) {
                obj['attachments'] = ApiClient.convertToType(data['attachments'], [Attachment]);
            }
            if (data.hasOwnProperty('reply')) {
                obj['reply'] = Message.constructFromObject(data['reply']);
            }
            if (data.hasOwnProperty('replyId')) {
                obj['replyId'] = ApiClient.convertToType(data['replyId'], 'String');
            }
            if (data.hasOwnProperty('author')) {
                obj['author'] = User.constructFromObject(data['author']);
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('isNew')) {
                obj['isNew'] = ApiClient.convertToType(data['isNew'], 'Boolean');
            }
        }
        return obj;
    }


}


Message.prototype['id'] = undefined;


Message.prototype['chatId'] = undefined;


Message.prototype['text'] = undefined;


Message.prototype['attachments'] = undefined;


Message.prototype['reply'] = undefined;


Message.prototype['replyId'] = undefined;


Message.prototype['author'] = undefined;


Message.prototype['timestamp'] = undefined;


Message.prototype['isNew'] = undefined;






export default Message;


/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class InlineObject4 {
    
    constructor(lastLogin) { 
        
        InlineObject4.initialize(this, lastLogin);
    }

    
    static initialize(obj, lastLogin) { 
        obj['lastLogin'] = lastLogin;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject4();

            if (data.hasOwnProperty('lastLogin')) {
                obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'Date');
            }
        }
        return obj;
    }


}


InlineObject4.prototype['lastLogin'] = undefined;






export default InlineObject4;


import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  devices: [],
  devicesTypes: {},
  devicesStatus: {},
  currentDevice: {}
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class OrderEdit {
    
    constructor(title, typeId, statusId, tagIds) { 
        
        OrderEdit.initialize(this, title, typeId, statusId, tagIds);
    }

    
    static initialize(obj, title, typeId, statusId, tagIds) { 
        obj['title'] = title;
        obj['typeId'] = typeId;
        obj['statusId'] = statusId;
        obj['tagIds'] = tagIds;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderEdit();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('texts')) {
                obj['texts'] = ApiClient.convertToType(data['texts'], {'String': 'String'});
            }
            if (data.hasOwnProperty('filePaths')) {
                obj['filePaths'] = ApiClient.convertToType(data['filePaths'], ['String']);
            }
            if (data.hasOwnProperty('typeId')) {
                obj['typeId'] = ApiClient.convertToType(data['typeId'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'String');
            }
            if (data.hasOwnProperty('tagIds')) {
                obj['tagIds'] = ApiClient.convertToType(data['tagIds'], ['String']);
            }
            if (data.hasOwnProperty('budget')) {
                obj['budget'] = ApiClient.convertToType(data['budget'], 'Number');
            }
            if (data.hasOwnProperty('estimatedTime')) {
                obj['estimatedTime'] = ApiClient.convertToType(data['estimatedTime'], 'Number');
            }
            if (data.hasOwnProperty('deadline')) {
                obj['deadline'] = ApiClient.convertToType(data['deadline'], 'Date');
            }
            if (data.hasOwnProperty('estimation')) {
                obj['estimation'] = ApiClient.convertToType(data['estimation'], 'Number');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('cost')) {
                obj['cost'] = ApiClient.convertToType(data['cost'], 'Number');
            }
        }
        return obj;
    }


}


OrderEdit.prototype['title'] = undefined;


OrderEdit.prototype['texts'] = undefined;


OrderEdit.prototype['filePaths'] = undefined;


OrderEdit.prototype['typeId'] = undefined;


OrderEdit.prototype['priority'] = undefined;


OrderEdit.prototype['statusId'] = undefined;


OrderEdit.prototype['tagIds'] = undefined;


OrderEdit.prototype['budget'] = undefined;


OrderEdit.prototype['estimatedTime'] = undefined;


OrderEdit.prototype['deadline'] = undefined;


OrderEdit.prototype['estimation'] = undefined;


OrderEdit.prototype['comment'] = undefined;


OrderEdit.prototype['cost'] = undefined;






export default OrderEdit;


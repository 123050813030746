/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Location from './Location';
import Tag from './Tag';
import User from './User';


class Device {
    
    constructor(officeId, type, serialNum) { 
        
        Device.initialize(this, officeId, type, serialNum);
    }

    
    static initialize(obj, officeId, type, serialNum) { 
        obj['officeId'] = officeId;
        obj['type'] = type;
        obj['serialNum'] = serialNum;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Device();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('techniqueId')) {
                obj['techniqueId'] = ApiClient.convertToType(data['techniqueId'], 'String');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = Location.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('serialNum')) {
                obj['serialNum'] = ApiClient.convertToType(data['serialNum'], 'String');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [Tag]);
            }
            if (data.hasOwnProperty('specialMarks')) {
                obj['specialMarks'] = ApiClient.convertToType(data['specialMarks'], 'String');
            }
            if (data.hasOwnProperty('stickerNum')) {
                obj['stickerNum'] = ApiClient.convertToType(data['stickerNum'], 'String');
            }
            if (data.hasOwnProperty('stockNum')) {
                obj['stockNum'] = ApiClient.convertToType(data['stockNum'], 'String');
            }
            if (data.hasOwnProperty('costPerPage')) {
                obj['costPerPage'] = ApiClient.convertToType(data['costPerPage'], 'Number');
            }
            if (data.hasOwnProperty('costPerPageColor')) {
                obj['costPerPageColor'] = ApiClient.convertToType(data['costPerPageColor'], 'Number');
            }
            if (data.hasOwnProperty('expenses')) {
                obj['expenses'] = ApiClient.convertToType(data['expenses'], 'Number');
            }
            if (data.hasOwnProperty('picturePath')) {
                obj['picturePath'] = ApiClient.convertToType(data['picturePath'], 'String');
            }
            if (data.hasOwnProperty('pictureUrl')) {
                obj['pictureUrl'] = ApiClient.convertToType(data['pictureUrl'], 'String');
            }
            if (data.hasOwnProperty('wear')) {
                obj['wear'] = ApiClient.convertToType(data['wear'], 'Number');
            }
            if (data.hasOwnProperty('wearColor')) {
                obj['wearColor'] = ApiClient.convertToType(data['wearColor'], 'Number');
            }
            if (data.hasOwnProperty('color')) {
                obj['color'] = ApiClient.convertToType(data['color'], 'Boolean');
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [File]);
            }
            if (data.hasOwnProperty('viewers')) {
                obj['viewers'] = ApiClient.convertToType(data['viewers'], [User]);
            }
        }
        return obj;
    }


}


Device.prototype['id'] = undefined;


Device.prototype['techniqueId'] = undefined;


Device.prototype['companyId'] = undefined;


Device.prototype['officeId'] = undefined;


Device.prototype['location'] = undefined;


Device.prototype['title'] = undefined;


Device.prototype['type'] = undefined;


Device.prototype['status'] = undefined;


Device.prototype['serialNum'] = undefined;


Device.prototype['tags'] = undefined;


Device.prototype['specialMarks'] = undefined;


Device.prototype['stickerNum'] = undefined;


Device.prototype['stockNum'] = undefined;


Device.prototype['costPerPage'] = undefined;


Device.prototype['costPerPageColor'] = undefined;


Device.prototype['expenses'] = undefined;


Device.prototype['picturePath'] = undefined;


Device.prototype['pictureUrl'] = undefined;


Device.prototype['wear'] = undefined;


Device.prototype['wearColor'] = undefined;


Device.prototype['color'] = undefined;


Device.prototype['files'] = undefined;


Device.prototype['viewers'] = undefined;






Device['TypeEnum'] = {

    
    "printer": "printer",

    
    "scanner": "scanner",

    
    "copier": "copier",

    
    "mfp": "mfp"
};



Device['StatusEnum'] = {

    
    "working": "working",

    
    "processing": "processing",

    
    "servicing": "servicing",

    
    "decommission": "decommission"
};



export default Device;


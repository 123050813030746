export default {
  setUserRoles: (state, data) => {
    state.userRoles = data;
  },
  setCurrentUser: (state, data) => {
    state.currentUser = data;
  },
  setCurrentUserV2: (state, data) => {
    state.currentUserV2 = data;
  },
  setAdminProfile: (state, data) => {
    state.adminProfile = data;
  }
};

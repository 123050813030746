import RequestManager from "../../../function/request/RequestManager";
export default {
  GET_BANK_INFO: ctx => {
    RequestManager()
      .getBankInfo()
      .then(data => {
        ctx.commit("setBankInfo", data);
      });
  }
};

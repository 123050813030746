/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Type {
    
    constructor(id, title) { 
        
        Type.initialize(this, id, title);
    }

    
    static initialize(obj, id, title) { 
        obj['id'] = id;
        obj['title'] = title;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Type();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'String');
            }
        }
        return obj;
    }


}


Type.prototype['id'] = undefined;


Type.prototype['title'] = undefined;


Type.prototype['categoryId'] = undefined;






export default Type;


/**
 * Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Chat from './Chat';


class ChatList {
    
    constructor(items, count, countWithNewMessages) { 
        
        ChatList.initialize(this, items, count, countWithNewMessages);
    }

    
    static initialize(obj, items, count, countWithNewMessages) { 
        obj['items'] = items;
        obj['count'] = count;
        obj['countWithNewMessages'] = countWithNewMessages;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChatList();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Chat]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
            if (data.hasOwnProperty('countWithNewMessages')) {
                obj['countWithNewMessages'] = ApiClient.convertToType(data['countWithNewMessages'], 'Number');
            }
        }
        return obj;
    }


}


ChatList.prototype['items'] = undefined;


ChatList.prototype['count'] = undefined;


ChatList.prototype['countWithNewMessages'] = undefined;






export default ChatList;


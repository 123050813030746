/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class NeoPharmRequest {
    
    constructor() { 
        
        NeoPharmRequest.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NeoPharmRequest();

            if (data.hasOwnProperty('method')) {
                obj['method'] = ApiClient.convertToType(data['method'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('headers')) {
                obj['headers'] = ApiClient.convertToType(data['headers'], {'String': 'String'});
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], {'String': 'String'});
            }
        }
        return obj;
    }


}


NeoPharmRequest.prototype['method'] = undefined;


NeoPharmRequest.prototype['url'] = undefined;


NeoPharmRequest.prototype['headers'] = undefined;


NeoPharmRequest.prototype['body'] = undefined;






export default NeoPharmRequest;


import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  bankInfo: {}
};
export default {
  state,
  mutations,
  getters,
  actions
};

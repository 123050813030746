/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Status from './Status';


class ChangeStatusHistoryAllOf {
    
    constructor(status) { 
        
        ChangeStatusHistoryAllOf.initialize(this, status);
    }

    
    static initialize(obj, status) { 
        obj['status'] = status;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangeStatusHistoryAllOf();

            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = Status.constructFromObject(data['status']);
            }
        }
        return obj;
    }


}


ChangeStatusHistoryAllOf.prototype['description'] = undefined;


ChangeStatusHistoryAllOf.prototype['status'] = undefined;






export default ChangeStatusHistoryAllOf;


/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Order {
    
    constructor(stickerNum, officeId, name, description) { 
        
        Order.initialize(this, stickerNum, officeId, name, description);
    }

    
    static initialize(obj, stickerNum, officeId, name, description) { 
        obj['stickerNum'] = stickerNum;
        obj['officeId'] = officeId;
        obj['name'] = name;
        obj['description'] = description;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Order();

            if (data.hasOwnProperty('deviceId')) {
                obj['deviceId'] = ApiClient.convertToType(data['deviceId'], 'String');
            }
            if (data.hasOwnProperty('stickerNum')) {
                obj['stickerNum'] = ApiClient.convertToType(data['stickerNum'], 'String');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
        }
        return obj;
    }


}


Order.prototype['deviceId'] = undefined;


Order.prototype['stickerNum'] = undefined;


Order.prototype['officeId'] = undefined;


Order.prototype['name'] = undefined;


Order.prototype['description'] = undefined;






export default Order;


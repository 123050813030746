<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkTokenInStorage } from "./function/checkToken";
import { refreshTokenV2 } from "./function/request/apiV2";

export default {
  name: "App",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    async refresh() {
      await refreshTokenV2();
    }
  },
  async mounted() {
    if (this.isAuthenticated || checkTokenInStorage()) {
      await this.$store.dispatch("wakeAuth");

      // this.$router.push("/main");
    } else {
      // this.$router.push("/");
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.refresh();
    }
  }
  // watch: {
  //   isAuthenticated(value) {
  //     if (value === false) {
  //       this.$router.push("/");
  //     }
  //   }
  // },
};
</script>
<style scoped lang="scss">
.message-modal {
  font-size: 18px;
}
</style>

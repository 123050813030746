/**
 * User API
 * Used for: - Manage User 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class InlineObject3 {
    
    constructor(newPassword) { 
        
        InlineObject3.initialize(this, newPassword);
    }

    
    static initialize(obj, newPassword) { 
        obj['newPassword'] = newPassword;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject3();

            if (data.hasOwnProperty('newPassword')) {
                obj['newPassword'] = ApiClient.convertToType(data['newPassword'], 'String');
            }
        }
        return obj;
    }


}


InlineObject3.prototype['newPassword'] = undefined;






export default InlineObject3;


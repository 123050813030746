/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';


class History {
    
    constructor(id, objectType, timestamp) { 
        
        History.initialize(this, id, objectType, timestamp);
    }

    
    static initialize(obj, id, objectType, timestamp) { 
        obj['id'] = id;
        obj['objectType'] = objectType;
        obj['timestamp'] = timestamp;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new History();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('objectType')) {
                obj['objectType'] = ApiClient.convertToType(data['objectType'], 'String');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
        }
        return obj;
    }


}


History.prototype['id'] = undefined;


History.prototype['objectType'] = undefined;


History.prototype['user'] = undefined;


History.prototype['timestamp'] = undefined;






History['ObjectTypeEnum'] = {

    
    "UpdateHistory": "UpdateHistory",

    
    "OrderHistory": "OrderHistory"
};



export default History;


import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  tags: {
    results: []
  }
};
export default {
  state,
  mutations,
  getters,
  actions
};

import RequestManager from "../../../function/request/RequestManager";
export default {
  GET_ALL_TAGS: ctx => {
    RequestManager()
      .getTagsList()
      .then(data => {
        ctx.commit("setTags", data);
      });
  }
};

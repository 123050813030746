import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const checkLocaleStorageInfo = () => {
  let isShowLabel;
  if (!localStorage.getItem("isShowHelpLabel")) {
    localStorage.setItem("isShowHelpLabel", "true");
    isShowLabel = true;
  } else {
    isShowLabel = localStorage.getItem("isShowHelpLabel")
      ? JSON.parse(localStorage.getItem("isShowHelpLabel"))
      : false;
  }

  return isShowLabel;
};

const state = {
  isAuth: false,
  role: 0,
  refreshed: false,
  isShowInfoLabels: checkLocaleStorageInfo()
};
export default {
  state,
  mutations,
  getters,
  actions
};

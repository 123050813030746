/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PermissionEdit from './PermissionEdit';
import ReferencesEdit from './ReferencesEdit';


class OrderCreate {
    
    constructor(companyId, title, typeId, statusId, tagIds) { 
        
        OrderCreate.initialize(this, companyId, title, typeId, statusId, tagIds);
    }

    
    static initialize(obj, companyId, title, typeId, statusId, tagIds) { 
        obj['companyId'] = companyId;
        obj['title'] = title;
        obj['typeId'] = typeId;
        obj['statusId'] = statusId;
        obj['tagIds'] = tagIds;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderCreate();

            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('texts')) {
                obj['texts'] = ApiClient.convertToType(data['texts'], {'String': 'String'});
            }
            if (data.hasOwnProperty('filePaths')) {
                obj['filePaths'] = ApiClient.convertToType(data['filePaths'], ['String']);
            }
            if (data.hasOwnProperty('typeId')) {
                obj['typeId'] = ApiClient.convertToType(data['typeId'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'String');
            }
            if (data.hasOwnProperty('tagIds')) {
                obj['tagIds'] = ApiClient.convertToType(data['tagIds'], ['String']);
            }
            if (data.hasOwnProperty('budget')) {
                obj['budget'] = ApiClient.convertToType(data['budget'], 'Number');
            }
            if (data.hasOwnProperty('estimatedTime')) {
                obj['estimatedTime'] = ApiClient.convertToType(data['estimatedTime'], 'Number');
            }
            if (data.hasOwnProperty('deadline')) {
                obj['deadline'] = ApiClient.convertToType(data['deadline'], 'Date');
            }
            if (data.hasOwnProperty('estimation')) {
                obj['estimation'] = ApiClient.convertToType(data['estimation'], 'Number');
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = ApiClient.convertToType(data['permissions'], [PermissionEdit]);
            }
            if (data.hasOwnProperty('references')) {
                obj['references'] = ReferencesEdit.constructFromObject(data['references']);
            }
        }
        return obj;
    }


}


OrderCreate.prototype['companyId'] = undefined;


OrderCreate.prototype['title'] = undefined;


OrderCreate.prototype['texts'] = undefined;


OrderCreate.prototype['filePaths'] = undefined;


OrderCreate.prototype['typeId'] = undefined;


OrderCreate.prototype['priority'] = undefined;


OrderCreate.prototype['statusId'] = undefined;


OrderCreate.prototype['tagIds'] = undefined;


OrderCreate.prototype['budget'] = undefined;


OrderCreate.prototype['estimatedTime'] = undefined;


OrderCreate.prototype['deadline'] = undefined;


OrderCreate.prototype['estimation'] = undefined;


OrderCreate.prototype['permissions'] = undefined;


OrderCreate.prototype['references'] = undefined;






export default OrderCreate;


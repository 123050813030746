<template>
  <div class="plus-button" @click="$emit('clicked')"></div>
</template>

<script>
export default {
  name: "IconPlus"
};
</script>

<style scoped lang="scss">
.plus-button {
  cursor: pointer;
  margin-left: 10px;
  border: 2px solid var(--plus-button-color);
  background-color: var(--main-item-bg);
  font-size: 16px;
  height: 2em;
  width: 2em;
  border-radius: 999px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  }

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: #006b90;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
}
</style>

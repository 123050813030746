/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class ReferencesEdit {
    
    constructor() { 
        
        ReferencesEdit.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReferencesEdit();

            if (data.hasOwnProperty('ordersIds')) {
                obj['ordersIds'] = ApiClient.convertToType(data['ordersIds'], ['String']);
            }
            if (data.hasOwnProperty('parentOrdersIds')) {
                obj['parentOrdersIds'] = ApiClient.convertToType(data['parentOrdersIds'], ['String']);
            }
            if (data.hasOwnProperty('devicesIds')) {
                obj['devicesIds'] = ApiClient.convertToType(data['devicesIds'], ['String']);
            }
        }
        return obj;
    }


}


ReferencesEdit.prototype['ordersIds'] = undefined;


ReferencesEdit.prototype['parentOrdersIds'] = undefined;


ReferencesEdit.prototype['devicesIds'] = undefined;






export default ReferencesEdit;


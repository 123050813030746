import RequestManager from "../../../function/request/RequestManager";

export default {
  async GET_NOTIFICATIONS({ commit }) {
    let items;

    try {
      items = await RequestManager.getNotifications();
    } catch (err) {
      //err
      items = [];
    } finally {
      commit("SET_NOTIFICATIONS", items);
    }
  }
};

/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Device from './Device';


class DeviceList {
    
    constructor(items, count) { 
        
        DeviceList.initialize(this, items, count);
    }

    
    static initialize(obj, items, count) { 
        obj['items'] = items;
        obj['count'] = count;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceList();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Device]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }


}


DeviceList.prototype['items'] = undefined;


DeviceList.prototype['count'] = undefined;






export default DeviceList;


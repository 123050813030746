import axios from "axios";
import { getCookie, setCookie } from "../cookie/cookie";
import store from "../../store";
import { CLEAR_ALL_ERRORS, SET_REFRESHED, SET_AUTH } from "../../store/types";

export default function SendRequest(
  requestType,
  requestUrl,
  requestData,
  headers = null,
  requestQuery = null,
  requestPrepare = null
) {
  const send = function(
    requestType,
    requestUrl,
    requestData,
    requestPrepare = null
    // requestQuery = null
  ) {
    if (requestType === "JSON-TEST") {
      requestType = "GET";
      requestUrl = "/request/" + requestUrl + ".json";
    }

    const axiosObject = {
      method: requestType,
      url: requestUrl,
      responseType: requestQuery ? requestQuery : "json"
    };
    if (headers) {
      axiosObject.headers = headers;
    }
    if (requestType === "GET") {
      axiosObject.params = requestData;
    } else {
      axiosObject.data = requestData;
    }
    return axios(axiosObject)
      .then(function(response) {
        if (Object.keys(store.getters.getErrors).length > 0) {
          store.dispatch(CLEAR_ALL_ERRORS);
        }
        response.data.code = response.code;
        return requestPrepare ? requestPrepare(response.data) : response.data;
      })
      .catch(function(error) {
        console.log(error);
        if (error?.response?.status === 400) {
          const errors = error.response.data;
          for (let key in errors) {
            errors[key] = errors[key][0];
          }
          store.dispatch("SET_ERRORS", errors);
        }
        if (error?.response?.status === 401) {
          store.dispatch(SET_AUTH, false);
          const refreshToken = getCookie("refreshToken");
          if (
            refreshToken !== undefined &&
            store.getters.isRefreshed === false
          ) {
            const data = {
              refresh: refreshToken
            };
            axios
              .post(process.env.VUE_APP_API_URL + "/token/refresh/", data)
              .then(response => {
                setCookie("accessToken", response.data.access);
                axios.defaults.headers = {
                  Authorization: `Bearer ${response.data.access}`
                };
                store.dispatch(SET_REFRESHED);
                return axios(axiosObject)
                  .then(function(response) {
                    return requestPrepare
                      ? requestPrepare(response.data)
                      : response.data;
                  })
                  .catch(error => {
                    return {
                      success: false,
                      logError: error
                    };
                  });
              })
              .catch(() => {
                delete axios.defaults.headers.Authorization;
                setCookie("accessToken", "", { "max-age": -1 });
                setCookie("refreshToken", "", { "max-age": -1 });
              });
          } else {
            delete axios.defaults.headers.Authorization;
            setCookie("accessToken", "", { "max-age": -1 });
            setCookie("refreshToken", "", { "max-age": -1 });
          }
        }
        return {
          success: false,
          logError: error
        };
      });
  };

  return send(
    requestType,
    requestUrl,
    requestData,
    requestPrepare,
    requestQuery
  );
}

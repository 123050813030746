/**
 * OFPR-Shop API
 * Used for: - Manage Product - Manage Order 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ShopOrder from '../model/ShopOrder';
import ShopOrderList from '../model/ShopOrderList';
import Status from '../model/Status';


export default class OrderApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    changeStatusOrderWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling changeStatusOrder");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling changeStatusOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ShopOrder;
      return this.apiClient.callApi(
        '/shop-order/{id}/change-status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    changeStatusOrder(id, body) {
      return this.changeStatusOrderWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrderWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createOrder");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ShopOrder;
      return this.apiClient.callApi(
        '/shop-order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrder(body) {
      return this.createOrderWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteOrderWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/shop-order/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteOrder(id) {
      return this.deleteOrderWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    editOrderWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editOrder");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling editOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ShopOrder;
      return this.apiClient.callApi(
        '/shop-order/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    editOrder(id, body) {
      return this.editOrderWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getOrderWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOrder;
      return this.apiClient.callApi(
        '/shop-order/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getOrder(id) {
      return this.getOrderWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    listOrderWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'csv'),
        'userIds': this.apiClient.buildCollectionParam(opts['userIds'], 'csv'),
        'completedAtMin': opts['completedAtMin'],
        'completedAtMax': opts['completedAtMax'],
        'createdAtMin': opts['createdAtMin'],
        'createdAtMax': opts['createdAtMax'],
        'offset': opts['offset'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'order': opts['order']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOrderList;
      return this.apiClient.callApi(
        '/shop-orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    listOrder(opts) {
      return this.listOrderWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * OFPR-Shop API
 * Used for: - Manage Product - Manage Order 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Position from './Position';


class ShopOrder {
    
    constructor(email, clientInfo, positions) { 
        
        ShopOrder.initialize(this, email, clientInfo, positions);
    }

    
    static initialize(obj, email, clientInfo, positions) { 
        obj['email'] = email;
        obj['clientInfo'] = clientInfo;
        obj['positions'] = positions;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShopOrder();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('clientInfo')) {
                obj['clientInfo'] = ApiClient.convertToType(data['clientInfo'], {'String': 'String'});
            }
            if (data.hasOwnProperty('positions')) {
                obj['positions'] = ApiClient.convertToType(data['positions'], [Position]);
            }
            if (data.hasOwnProperty('systemOrderId')) {
                obj['systemOrderId'] = ApiClient.convertToType(data['systemOrderId'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
            if (data.hasOwnProperty('completedAt')) {
                obj['completedAt'] = ApiClient.convertToType(data['completedAt'], 'Date');
            }
        }
        return obj;
    }


}


ShopOrder.prototype['id'] = undefined;


ShopOrder.prototype['title'] = undefined;


ShopOrder.prototype['email'] = undefined;


ShopOrder.prototype['clientInfo'] = undefined;


ShopOrder.prototype['positions'] = undefined;


ShopOrder.prototype['systemOrderId'] = undefined;


ShopOrder.prototype['status'] = undefined;


ShopOrder.prototype['createdAt'] = undefined;


ShopOrder.prototype['updatedAt'] = undefined;


ShopOrder.prototype['completedAt'] = undefined;






export default ShopOrder;


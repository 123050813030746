/**
 * OFPR-Shop API
 * Used for: - Manage Product - Manage Order 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Product {
    
    constructor(techniqueId, price, enabled, createdAt, updatedAt) { 
        
        Product.initialize(this, techniqueId, price, enabled, createdAt, updatedAt);
    }

    
    static initialize(obj, techniqueId, price, enabled, createdAt, updatedAt) { 
        obj['techniqueId'] = techniqueId;
        obj['price'] = price;
        obj['enabled'] = enabled;
        obj['createdAt'] = createdAt;
        obj['updatedAt'] = updatedAt;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Product();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('techniqueId')) {
                obj['techniqueId'] = ApiClient.convertToType(data['techniqueId'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
        }
        return obj;
    }


}


Product.prototype['id'] = undefined;


Product.prototype['techniqueId'] = undefined;


Product.prototype['price'] = undefined;


Product.prototype['enabled'] = undefined;


Product.prototype['createdAt'] = undefined;


Product.prototype['updatedAt'] = undefined;






export default Product;


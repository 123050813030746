/**
 * Technique API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class TechniqueAllOf {
    
    constructor(id, sku) { 
        
        TechniqueAllOf.initialize(this, id, sku);
    }

    
    static initialize(obj, id, sku) { 
        obj['id'] = id;
        obj['sku'] = sku;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TechniqueAllOf();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
        }
        return obj;
    }


}


TechniqueAllOf.prototype['id'] = undefined;


TechniqueAllOf.prototype['sku'] = undefined;






export default TechniqueAllOf;


<template>
  <header class="header pr-5 pl-5">
    <div
      class="header__block d-flex justify-content-center align-items-center position-relative"
    >
      <router-link class="header__avatar-link" :to="{ name: 'Main' }">
        <img
          v-if="
            currentUser &&
              currentUser.id &&
              (currentUser.profile_picture || currentUser.profilePicture)
          "
          :src="getImage(currentUser.profile_picture)"
          alt="ava"
          width="44"
          height="44"
          class="header__avatar"
        />
        <img
          v-if="
            currentUser &&
              currentUser.id &&
              !currentUser.profile_picture &&
              !currentUser.profilePicture
          "
          src="../resource/img/no-ava.svg"
          alt="ava"
          style="filter: var(--filter-for-no-ava-svg)"
        />
        <help-popover
          v-if="currentUser && currentUser.id && helpPopovers.PopoverOfMenuHelp"
          :message="helpPopovers.PopoverOfAvatarHelp"
          class="ml-2 help-icon"
          :is-inline-block="true"
          style="position: absolute; right: 50%; top: -5px"
        />
      </router-link>
      <div
        v-if="currentUser && currentUser.id"
        class="header__menu-back ml-4 position-relative"
      >
        <img @click="routerMethod" :src="logout" class="logout" alt="" />
      </div>
      <help-popover
        v-if="
          currentUser && currentUser.id && helpPopovers.PopoverOfTurnBackHelp
        "
        :message="helpPopovers.PopoverOfTurnBackHelp"
        class="ml-2 help-icon"
        :is-inline-block="false"
        style="position: absolute; top: -8px"
      />
    </div>

    <div v-if="title" class="all-title-page d-flex align-items-center">
      {{ title }}
      <slot name="additionalTitle"></slot>
    </div>

    <div
      v-if="currentUser && currentUser.id"
      class="header__block header__block_menu"
    >
      <div
        class="header__menu-toggle"
        @click="toggleInfo"
        title="Показать/скрыть подсказки"
      >
        <span class="header__menu-toggle-icon" :class="isShowInfo">
          ?
          <help-popover
            v-if="helpPopovers.AddOrDeleteSymbol"
            :message="helpPopovers.AddOrDeleteSymbol"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="top: -22px; right: -4px"
          />
        </span>
      </div>

      <!-- <div class="header__menu position-relative">
        <help-popover
          v-if="helpPopovers.PopoverOfGoToShopHelp"
          :message="helpPopovers.PopoverOfGoToShopHelp"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="top: -5px"
        />
        <router-link to="/shop" class="header__link p-3">
          <img
            src="../resource/img/shop-50.png"
            width="24"
            height="24"
            alt="shop"
          />
          Магазин
        </router-link>
      </div> -->

      <div class="header__menu position-relative">
        <help-popover
          v-if="helpPopovers.PopoverOfGoToOrdersHelp"
          :message="helpPopovers.PopoverOfGoToOrdersHelp"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="top: -5px"
        />
        <router-link to="/request-list" class="header__link p-3">
          <img src="../resource/img/orders.png" alt="orders" />
          Задачи
        </router-link>
      </div>

      <div class="header__menu position-relative">
        <help-popover
          v-if="helpPopovers.PopoverOfGoToDeviceHelp"
          :message="helpPopovers.PopoverOfGoToDeviceHelp"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="top: -5px"
        />
        <router-link to="/my-techique" class="header__link p-3">
          <img src="../resource/img/devices.jpeg" height="34px" alt="devices" />
          Техника
        </router-link>
      </div>

      <div
        class="header__menu position-relative home-anchor"
        :class="{ 'over-top': !isIntroPassed }"
        @click="emitGetMainData"
      >
        <help-popover
          v-if="helpPopovers.PopoverOfMenuHelp"
          :message="helpPopovers.PopoverOfMenuHelp"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="top: -5px"
        />
        <router-link to="/main" class="header__link p-3">
          <img src="../resource/img/menu.svg" alt="menu" />
          Меню
        </router-link>
      </div>

      <div class="header__menu position-relative">
        <help-popover
          v-if="helpPopovers.PopoverOfGoToShopHelp"
          :message="helpPopovers.PopoverOfGoToShopHelp"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="top: -5px"
        />
        <router-link to="/shop" class="header__link p-3">
          <img src="../resource/img/iShop.png" height="34px" alt="shop" />
          Магазин
        </router-link>
      </div>

      <div class="header__menu position-relative">
        <router-link to="/chats" class="header__link">
          <svg
            width="51"
            height="51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25.5" cy="25.5" r="25.5" fill-opacity=".15" />
            <g clip-path="url(#clip0)">
              <path
                d="M25.552 17.37c-4.483 0-8.13 3.647-8.13 8.13 0 4.483 3.647 8.13 8.13 8.13 4.483 0 8.13-3.647 8.13-8.13 0-4.483-3.647-8.13-8.13-8.13zM21.46 31.45v-2.85a1.74 1.74 0 011.527-1.722 3.377 3.377 0 005.129 0 1.74 1.74 0 011.528 1.722v2.85c-1.194.801-2.576 1.271-4.092 1.271-1.516 0-2.898-.47-4.092-1.271zm9.093-.741v-2.109a2.652 2.652 0 00-2.649-2.644.455.455 0 00-.366.185 2.465 2.465 0 01-3.973 0 .454.454 0 00-.366-.185 2.652 2.652 0 00-2.648 2.644v2.109a7.224 7.224 0 01-2.222-5.208c0-3.981 3.24-7.22 7.222-7.22 3.98 0 7.223 3.239 7.223 7.22a7.222 7.222 0 01-2.221 5.208z"
              />
              <path
                d="M36.587 26.776c.11-1.014.083-2.038-.08-3.045l1.259-1.206a.454.454 0 00.102-.51l-1.433-3.275a.454.454 0 00-.45-.271l-1.741.128a10.258 10.258 0 00-2.207-2.103l.05-1.74a.455.455 0 00-.29-.437l-3.333-1.286a.455.455 0 00-.508.127l-1.128 1.308a11.423 11.423 0 00-3.045.079l-1.207-1.259a.455.455 0 00-.51-.102l-3.274 1.433a.454.454 0 00-.272.45l.128 1.742a10.261 10.261 0 00-2.102 2.207l-1.74-.051a.452.452 0 00-.437.29l-1.286 3.333a.454.454 0 00.127.508l1.308 1.129a11.403 11.403 0 00.079 3.044l-1.259 1.207a.454.454 0 00-.102.51l1.433 3.274a.454.454 0 00.45.272l1.742-.128c.627.806 1.37 1.515 2.207 2.102l-.051 1.74a.454.454 0 00.29.437l3.333 1.287c.18.069.383.018.508-.127l1.129-1.309c1.013.11 2.037.084 3.044-.078l1.206 1.258a.455.455 0 00.51.102l3.275-1.433a.454.454 0 00.271-.45l-.128-1.741a10.265 10.265 0 002.102-2.208l1.74.051a.455.455 0 00.438-.29l1.286-3.333a.455.455 0 00-.127-.508l-1.307-1.128zm-.585 4.341l-1.666-.049a.453.453 0 00-.392.202 9.365 9.365 0 01-2.23 2.343.455.455 0 00-.183.399l.122 1.667-2.689 1.176-1.154-1.203a.454.454 0 00-.41-.133c-1.073.197-2.17.225-3.251.084a.454.454 0 00-.403.154l-1.077 1.249-2.734-1.055.049-1.667a.455.455 0 00-.202-.391 9.37 9.37 0 01-2.343-2.23.455.455 0 00-.399-.184l-1.667.123-1.176-2.69 1.203-1.154c.11-.105.16-.26.133-.41a10.512 10.512 0 01-.084-3.25.455.455 0 00-.154-.403l-1.249-1.078 1.055-2.734 1.667.049a.455.455 0 00.391-.202 9.375 9.375 0 012.231-2.343.454.454 0 00.183-.399l-.122-1.667 2.689-1.176 1.154 1.203c.105.11.26.16.41.133a10.505 10.505 0 013.252-.084.455.455 0 00.402-.154l1.078-1.249 2.734 1.055-.05 1.667a.455.455 0 00.203.391 9.367 9.367 0 012.342 2.231.455.455 0 00.4.183l1.666-.122 1.177 2.689-1.204 1.154a.454.454 0 00-.132.41c.196 1.073.224 2.17.084 3.25a.454.454 0 00.154.404l1.248 1.077-1.056 2.734z"
              />
              <path
                d="M25.552 20.107a2.888 2.888 0 100 5.775 2.888 2.888 0 000-5.775zm0 4.866a1.978 1.978 0 110-3.957 1.978 1.978 0 010 3.957z"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path
                  fill="#fff"
                  transform="translate(13 13)"
                  d="M0 0h25v25H0z"
                />
              </clipPath>
            </defs>
          </svg>

          Меню
          <help-popover
            v-if="helpPopovers.PopoverOfChatWithManager"
            :message="helpPopovers.PopoverOfChatWithManager"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="top: -2px"
          />
        </router-link>
      </div>

      <div class="header__notification ">
        <b-button
          id="popover-1"
          class="header__notification-link position-relative"
          :class="{
            'header__notification-link_active': _notifications.length > 0
          }"
          variant="primary"
        >
          <img src="../resource/img/notification.svg" alt="menu" />
          Нотификации
          <help-popover
            v-if="helpPopovers.PopoverOfNotificationHelp"
            :message="helpPopovers.PopoverOfNotificationHelp"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="right: 15px"
          />
        </b-button>

        <b-popover
          custom-class="header__notification-popover popover-notice"
          target="popover-1"
          triggers="hover focus"
          placement="bottom"
          :show.sync="showPopover"
        >
          <div class="scroll-container">
            <vuescroll>
              <!-- <div>{{ _notifications }}</div> -->
              <div
                v-for="(notification, index) in _notifications"
                :key="index"
                class="popover-notice__item"
              >
                <b-avatar
                  class="popover-notice__avatar"
                  variant="info"
                  :src="
                    notification.author !== null &&
                    notification.author !== undefined
                      ? getImage(notification.author.profile_picture)
                      : '../resource/img/no-ava.svg'
                  "
                ></b-avatar>

                <div
                  class="popover-notice__wrap link"
                  @click="goToMaterial(notification)"
                >
                  <div>{{ notification.title }}</div>
                  <h2
                    v-if="
                      notification.author !== null &&
                        notification.author !== undefined
                    "
                    class="popover-notice__title"
                  >
                    {{ notification.author.name }}
                  </h2>
                  <!-- Что-то из этого лишнее -->
                  <p class="popover-notice__text">
                    {{ notification.title }}
                  </p>
                  <p class="popover-notice__text">
                    {{ notification.description }}
                  </p>
                  <!-- --- -->
                  <time class="popover-notice__time">{{
                    getHours(notification.created_at)
                  }}</time>
                </div>
              </div>
            </vuescroll>
          </div>
        </b-popover>
      </div>
      <div class="position-relative">
        <div class="header__menu">
          <img
            @click.once="onLogoutClick"
            :src="exit"
            class="logout"
            :class="{ 'over-top': !isIntroPassed }"
            alt=""
            style="width: 28px; height: 28px;"
          />
          <help-popover
            v-if="helpPopovers.PopoverOfLogOut"
            :message="helpPopovers.PopoverOfLogOut"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="top: -13px; right: -4px"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <!-- <router-link to="/auth" class="header__link p-3"> -->
      <span
        v-if="isCurrentRouteContainsShop"
        class="header__link p-3"
        @click="goToAuthWithRedirect"
      >
        <img
          src="../resource/img/password.png"
          class="auth-icon"
          alt="orders"
          height="44"
          title="Авторизация"
        />
      </span>
      <!-- </router-link> -->
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import RequestManager from "../function/request/RequestManager";
import { SET_AUTH, SET_CURRENT_USER } from "../store/types";
import { mapActions } from "vuex";
import vuescroll from "vuescroll";
import helpPopover from "./HelpPopover";
import eventBus from "@/utils/event-bus";
import { SettingsUserApi } from "../function/request/apiV2";

export default {
  name: "Header",
  components: { vuescroll, helpPopover },
  props: {
    msg: String,
    userPage: Boolean,
    title: String
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    isShowInfo() {
      return this.$store.getters.isShowInformation
        ? "information-show"
        : "information-hide";
    },
    _notifications() {
      return this.notifications.filter(ntf => !ntf.is_read);
    },
    isCurrentRouteContainsShop() {
      return (
        location.pathname.indexOf("shop") > -1 ||
        location.pathname.indexOf("cart") > -1 ||
        location.pathname.indexOf("payment") > -1
      );
    }
  },
  created() {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
  },
  mounted() {
    if (this.isAuthenticated) {
      RequestManager()
        .getNotifications()
        .then(data => {
          if (data.length !== this.notifications.length) {
            this.$set(this, "notifications", data);
          }
        });
    }
    this.routeName = this.$route.name;

    eventBus.$on("site_notification", message => {
      const newMessage = message.payload;
      newMessage.created_at = new Date().toISOString();
      this.notifications.unshift(newMessage);
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      helpPopovers: {
        PopoverOfAvatarHelp:
          "Аватарка профиля, при нажатии вы попадете в главное меню",
        PopoverOfMenuHelp: "Кнопка перехода в главное меню",
        PopoverOfGoToOrdersHelp: "Переход в раздел задачи",
        PopoverOfGoToDeviceHelp: "Переход в раздел техника",
        PopoverOfGoToShopHelp: "Переход в интернет магазин",
        PopoverOfTurnBackHelp:
          "Кнопка перехода на предыдущую, посещенную вами, страницу",
        PopoverOfChatWithManager:
          "Кнопка перехода в чат с персональным менеджером",
        PopoverOfNotificationHelp: "Выпадающий список оповещений в системе",
        PopoverOfLogOut: "Кнопка выхода из системы",
        AddOrDeleteSymbol: "Показать/убрать все знаки вопросов в системе"
      },
      interval: null,
      noAva: require("../resource/img/no-ava.svg"),
      notifications: [],
      logout: require("../resource/img/logout.svg"),
      exit: require("../resource/img/exit-logo.svg"),
      showPopover: false,
      theme: "light-theme",
      isIntroPassed: true,
      routeName: "",
      currentUser: {},

      settingsUserApi: new SettingsUserApi()
    };
  },
  methods: {
    ...mapActions({ SET_AUTH, SET_CURRENT_USER }),

    goToAuthWithRedirect() {
      this.$router.push({
        name: "Auth",
        query: {
          redirect: location.pathname
        }
      });
    },

    async SaveParamsUser() {
      const items = await this.settingsUserApi.api.editUserSettings(
        this.currentUser.id,
        [{ name: "ShowQuestions", value: { type: "StringValue", value: "no" } }]
      );
      console.log(items);
    },

    emitGetMainData() {
      eventBus.$emit("token-v2-set");
    },

    routerMethod() {
      if (this.hasHistory()) {
        let mode = localStorage.getItem("mode");
        if (
          this.$router.history.current.params?.mode &&
          this.$router.history.current.params?.mode === "createOrder"
        ) {
          this.$router.go(-2);
        } else if (
          this.$router.history.current.params?.mode &&
          this.$router.history.current.params?.mode === "createUser"
        ) {
          this.$router.go(-3);
        } else if (
          this.$router.history.current.params?.mode &&
          this.$router.history.current.params?.mode === "createOffice"
        ) {
          this.$router.go(-3);
        } else if (mode && mode === "createTechnique") {
          localStorage.setItem("mode", undefined);
          if (this.$router.history.current.name === "TehniquePage") {
            this.$router.go(-2);
          }
        } else {
          this.$router.go(-1);
        }
      } else {
        this.$router.push("/");
      }
    },

    hasHistory() {
      return window.history.length > 2;
    },
    toggleInfo() {
      //this.SaveParamsUser();
      this.$store.dispatch("TOGGLE_SHOW_INFO");
    },
    goToMaterial(item) {
      console.log("goMaterial");
      if (
        item.data.type === "order_chat" ||
        item.data.type === "order_create" ||
        item.data.type === "order_change_status"
      ) {
        this.$router
          .push({
            name: "RequestChat",
            params: { id: item.data.order_chat_id }
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (item.data.type === "chat") {
        this.$router.push({
          name: "Chats",
          params: { dataUsers: { id: item.data.id } }
        });
      }
      if (item.data.type === "company_chat") {
        this.$router
          .push({
            name: "ManagerChat"
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (
        item.data.type === "order.create" ||
        item.data.type === "order.edit"
      ) {
        this.$router
          .push({
            name: "Order",
            params: { id: item.data.id }
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.showPopover = false;
    },
    onLogoutClick() {
      this.$store.dispatch("logout");
      this.$router.push({ path: "/" });
    },
    getImage(src) {
      return process.env.VUE_APP_URL + src;
    },
    getHours(date) {
      const notificationDate = new Date(date);
      const dateNow = new Date();
      var min = Math.ceil(
        Math.abs(dateNow.getTime() - notificationDate.getTime()) / (1000 * 60)
      );
      let prefix = "минут";
      if (min <= 60) {
        const lastnumber = +min.toString().charAt(min.toString().length - 1);
        if (lastnumber < 5 && lastnumber > 1) {
          prefix = "минуты";
        }
        if (lastnumber === 1) {
          prefix = "минута";
        }
        if (min > 10 && min < 20) {
          prefix = "минут";
        }

        return min + " " + prefix + " назад";
      } else {
        const hours = Math.floor(min / 60);
        const lastnumber = +hours
          .toString()
          .charAt(hours.toString().length - 1);
        if (lastnumber < 5 && lastnumber > 1) {
          prefix = "часа";
        } else if (lastnumber === 1) {
          prefix = "час";
        } else {
          prefix = "часов";
        }
        return hours + " " + prefix + " назад";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scroll-container {
  width: 100%;
  overflow: hidden;
  max-height: 78.5vh;
}
.logout {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 0 0 25px 25px;
  min-height: 79px;
}

@media (min-width: 320px) {
  .header {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
@media (min-width: 375px) {
  .header {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

@media (min-width: 425px) {
  .header {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.header__avatar {
  // background: #cecece;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.header__avatar-link {
  font-size: 0;
}

.header__notification-link {
  position: relative;
  font-size: 0;
  outline: none;

  &_active {
    &::after {
      content: "";
      position: absolute;
      top: 15px;
      right: 20px;

      width: 9px;
      height: 9px;
      background: #fb5717;

      border-radius: 50%;
    }
  }
}

.header__link {
  display: inline-block;
}

.header__block {
  &_menu {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: center;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    max-width: 390px;
    width: 100%;
  }
}
.help-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.header__notification,
.header__menu {
  font-size: 0;

  &-back {
    margin-right: 15px;
  }

  &-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-right: 15px;
    border-radius: 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &-icon {
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: var(--main-text-color);
      padding: 0 8px;
    }

    &-icon.information-show {
      &:after {
        position: absolute;
        content: "";
        top: 15px;
        left: 0;
        width: 25px;
        height: 2px;
        background-color: #fb5717;
        transform: rotate(45deg);
      }
    }
  }
}

.header__notification {
  margin-right: 10px;
}

.header__notification-popover {
  padding: 25px 15px 0;
  width: 100%;
  max-width: 340px;
}

.popover {
  background-color: var(--main-card-color);
}
.popover-notice__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;

  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 17px;

  &:not(:last-child) {
    padding-bottom: 10px;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.popover-notice__badge-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  width: 38px;
  height: 38px;
  border-radius: 50%;

  background: rgba(81, 162, 190, 0.15);
}

.popover-notice__wrap {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 90%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;

    width: 20px;
    height: 20px;

    background-image: url("../resource/img/chats-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &_no-msg {
    &::after {
      content: none;
    }
  }
}

.link {
  cursor: pointer;
}

.popover-notice__avatar {
  margin-right: 15px;
  border-radius: 50%;

  &_badge {
    width: 16px;
    height: auto;
    margin-right: -2px;
    border-radius: 0;
  }
}

.popover-notice__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--card-text-color);
}

.popover-notice__text {
  margin-bottom: 5px;
  padding: 0;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.04em;
  max-width: 89%;

  color: var(--card-text-color);
}

.popover-notice__time {
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: var(--main-text-color);

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 13px;
    height: 13px;

    background-image: url("../resource/img/clock.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

img {
  filter: var(--filter-for-svg);
}

circle {
  fill: var(--main-card-color);
}
g {
  fill: var(--main-text-color);
}

.btn:hover {
  background-color: var(--main-card-color);
}

.over-top {
  background-color: #fff;
}

.auth-icon {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
}
</style>

/**
 * Neo-pharm integration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class NeoPharmOrder {
    
    constructor(deviceId, name, description) { 
        
        NeoPharmOrder.initialize(this, deviceId, name, description);
    }

    
    static initialize(obj, deviceId, name, description) { 
        obj['deviceId'] = deviceId;
        obj['name'] = name;
        obj['description'] = description;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NeoPharmOrder();

            if (data.hasOwnProperty('deviceId')) {
                obj['deviceId'] = ApiClient.convertToType(data['deviceId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('priorityId')) {
                obj['priorityId'] = ApiClient.convertToType(data['priorityId'], 'String');
            }
            if (data.hasOwnProperty('serviceId')) {
                obj['serviceId'] = ApiClient.convertToType(data['serviceId'], 'String');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'String');
            }
            if (data.hasOwnProperty('typeId')) {
                obj['typeId'] = ApiClient.convertToType(data['typeId'], 'String');
            }
            if (data.hasOwnProperty('executorGroupId')) {
                obj['executorGroupId'] = ApiClient.convertToType(data['executorGroupId'], 'String');
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'String');
            }
            if (data.hasOwnProperty('impactedUserId')) {
                obj['impactedUserId'] = ApiClient.convertToType(data['impactedUserId'], 'String');
            }
            if (data.hasOwnProperty('observerId')) {
                obj['observerId'] = ApiClient.convertToType(data['observerId'], 'String');
            }
        }
        return obj;
    }


}


NeoPharmOrder.prototype['deviceId'] = undefined;


NeoPharmOrder.prototype['name'] = undefined;


NeoPharmOrder.prototype['description'] = undefined;


NeoPharmOrder.prototype['priorityId'] = undefined;


NeoPharmOrder.prototype['serviceId'] = undefined;


NeoPharmOrder.prototype['statusId'] = undefined;


NeoPharmOrder.prototype['typeId'] = undefined;


NeoPharmOrder.prototype['executorGroupId'] = undefined;


NeoPharmOrder.prototype['categoryId'] = undefined;


NeoPharmOrder.prototype['impactedUserId'] = undefined;


NeoPharmOrder.prototype['observerId'] = undefined;






export default NeoPharmOrder;


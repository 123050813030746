/**
 * Orders API
 * Orders API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrdersList from './OrdersList';


class OrderHierarchyAllOf {
    
    constructor() { 
        
        OrderHierarchyAllOf.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderHierarchyAllOf();

            if (data.hasOwnProperty('childs')) {
                obj['childs'] = OrdersList.constructFromObject(data['childs']);
            }
        }
        return obj;
    }


}


OrderHierarchyAllOf.prototype['childs'] = undefined;






export default OrderHierarchyAllOf;


// AUTH TYPES
export const SET_AUTH = "SET_AUTH";
export const SET_ROLE = "SET_ROLE";
export const SET_REFRESHED = "SET_REFRESHED";
// OFFICE TYPES
export const GET_ALL_OFFICES = "GET_ALL_OFFICES";
export const SET_NEW_OFFICE = "SET_NEW_OFFICE";

// DEVICE TYPES
export const GET_DEVICES_TYPES = "devices/GET_DEVICES_TYPES";
export const GET_DEVICES_STATUS = "devices/GET_DEVICES_STATUS";
export const SET_NEW_DEVICE = "devices/SET_NEW_DEVICE";
export const GET_ALL_DEVICES = "devices/GET_ALL_DEVICES";

// TAGS
export const GET_ALL_TAGS = "GET_ALL_TAGS";

// USER
export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// ERRORS
export const CLEAR_SINGLE_ERROR = "CLEAR_SINGLE_ERROR";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";

// MAIN COMPANY
export const GET_MAIN_COMPANY_INFO = "GET_MAIN_COMPANY_INFO";

// BANK INFO
export const GET_BANK_INFO = "GET_BANK_INFO";

// // NOTIFICATIONS
// export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

import { translateErrors } from "../../../function/errors/errors";
export default {
  SET_ERRORS: (ctx, data) => {
    ctx.commit("setErrors", translateErrors(data));
  },
  CLEAR_SINGLE_ERROR: (ctx, field) => {
    ctx.commit("clearSingleError", field);
  },
  CLEAR_ALL_ERRORS: ctx => {
    ctx.commit("clearErrors");
  }
};

/**
 * Company API
 * Used for: - Manage Company 
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CompanyRegistration {
    
    constructor(email, inn) { 
        
        CompanyRegistration.initialize(this, email, inn);
    }

    
    static initialize(obj, email, inn) { 
        obj['email'] = email;
        obj['inn'] = inn;
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyRegistration();

            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('inn')) {
                obj['inn'] = ApiClient.convertToType(data['inn'], 'String');
            }
        }
        return obj;
    }


}


CompanyRegistration.prototype['email'] = undefined;


CompanyRegistration.prototype['inn'] = undefined;






export default CompanyRegistration;


/**
 * Device API
 * Used for: - Manage Device 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Counter from '../model/Counter';
import CounterHistory from '../model/CounterHistory';
import CounterImport from '../model/CounterImport';
import Report from '../model/Report';


export default class CounterApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    deviceCountersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deviceCounters");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'codeNames': this.apiClient.buildCollectionParam(opts['codeNames'], 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Counter];
      return this.apiClient.callApi(
        '/device/{id}/counters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceCounters(id, opts) {
      return this.deviceCountersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceCountersAddWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deviceCountersAdd");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling deviceCountersAdd");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Counter;
      return this.apiClient.callApi(
        '/device/{id}/counters/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceCountersAdd(id, body) {
      return this.deviceCountersAddWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceCountersHistoryWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deviceCountersHistory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'codeNames': this.apiClient.buildCollectionParam(opts['codeNames'], 'csv')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CounterHistory];
      return this.apiClient.callApi(
        '/device/{id}/counters/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceCountersHistory(id, opts) {
      return this.deviceCountersHistoryWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceCountersImportWithHttpInfo(file) {
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling deviceCountersImport");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = [CounterImport];
      return this.apiClient.callApi(
        '/devices/counters/import', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceCountersImport(file) {
      return this.deviceCountersImportWithHttpInfo(file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deviceCountersResetWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deviceCountersReset");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling deviceCountersReset");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Counter;
      return this.apiClient.callApi(
        '/device/{id}/counters/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deviceCountersReset(id, body) {
      return this.deviceCountersResetWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    devicesCountersReportWithHttpInfo(ids, codeNames, dateTimeMin, dateTimeMax) {
      let postBody = null;
      // verify the required parameter 'ids' is set
      if (ids === undefined || ids === null) {
        throw new Error("Missing the required parameter 'ids' when calling devicesCountersReport");
      }
      // verify the required parameter 'codeNames' is set
      if (codeNames === undefined || codeNames === null) {
        throw new Error("Missing the required parameter 'codeNames' when calling devicesCountersReport");
      }
      // verify the required parameter 'dateTimeMin' is set
      if (dateTimeMin === undefined || dateTimeMin === null) {
        throw new Error("Missing the required parameter 'dateTimeMin' when calling devicesCountersReport");
      }
      // verify the required parameter 'dateTimeMax' is set
      if (dateTimeMax === undefined || dateTimeMax === null) {
        throw new Error("Missing the required parameter 'dateTimeMax' when calling devicesCountersReport");
      }

      let pathParams = {
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(ids, 'csv'),
        'codeNames': this.apiClient.buildCollectionParam(codeNames, 'csv'),
        'dateTimeMin': dateTimeMin,
        'dateTimeMax': dateTimeMax
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Report];
      return this.apiClient.callApi(
        '/devices/counters/report', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    devicesCountersReport(ids, codeNames, dateTimeMin, dateTimeMax) {
      return this.devicesCountersReportWithHttpInfo(ids, codeNames, dateTimeMin, dateTimeMax)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
